import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import FileUploader from 'react-firebase-file-uploader';
import * as CONFIG from '../../constants/config';

class uploader extends Component {
    
  constructor(props) {
    super(props);
    this.onUploadSuccess = props.onUploadSuccess;
    this.state = {
        username: '',
        avatar: '',
        isUploading: false,
        progress: 0,
        avatarURL: this.props.imageUrl ? this.props.imageUrl : '',
        showSizeError: false
    };
  }

    handleUploadStart = (event) => {
      if (event.size < 5000000) {
        this.setState({isUploading: true, progress: 0, showSizeError: false})
      } else {
        this.setState({ showSizeError: true, progress: 0 })
      }
    };
    handleProgress = (progress) => this.setState({progress});
    handleUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
    }
    handleUploadSuccess = (filename) => {
        this.setState({avatar: filename, progress: 100, isUploading: false});
        this.props.firebase.storage().ref(CONFIG.CURRENT_EDITION + '_images').child(filename).getDownloadURL().then(
            url => {
               this.setState({avatarURL: url});
               this.onUploadSuccess(url, this.props.imageField)
            }
         );
    };

  render() {
    return (
        <div>
        <form>

        {this.state.showSizeError &&
        <p className="text-danger">La imatge supera els 5Mb. Si us plau redueix el tamany i torna a intentar-ho.</p>
        }
        {this.state.avatarURL &&
        <img alt="this.state.avatarURL" src={this.state.avatarURL} style={{ maxWidth: '100%', padding: '10px', border: '1px solid #CCC', margin: '10px 0' }} />
        }
        <div className="form-group custom-input-file">
          {!this.state.isUploading &&
          <label className="btn btn-secondary">Seleccionar...</label>
          }
          {this.state.isUploading &&
          <label className="btn btn-secondary">Progrés: {this.state.progress}</label>
          } 
          <FileUploader
              accept="image/*"
              name="avatar"
              className="form-control-file"
              randomizeFilename
              storageRef={this.props.firebase.storage().ref(CONFIG.CURRENT_EDITION + '_images')}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
          />
        </div>
        </form>
        </div>);
  }
}

export default compose(
    withFirebase
  )(uploader);
