import Axios from 'axios';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import FileUploader from '../FileUploader';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { css, cx } from '@emotion/react'

class FilmsForm extends Component {

  constructor(props) {
    super(props);
    this.setRef = ref => {
      this.file = ref;
    }
    this.formData = {};

    // Get User role and identify if it's admin or editor
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    this.admin = this.authUser ? ( this.authUser.roles['ADMIN'] ? true : false ) : false;
    this.editor = this.authUser ? ( this.authUser.roles['EDITOR'] ? true : false ) : false;

    // Set default state
    this.state = {
      form: [],
      testSubmit: false, // TRUE or FALSE to make form simple to test
      success: false,
      premiere: true,
      country: 'Espanya',
      state: undefined,
      category: undefined,
      validated: null,
      formStructure: [],
      data: {},
    };

    const code = this.props.location.search.split('?code=')[1];



    if (code !== undefined && CONFIG.SIGNIN_FILMS_CODE.indexOf(code) !== -1) {
      this.overRideAccess = true;
    } else {
      this.overRideAccess = false;
    } 

    // Identify category based on URL match
    const handle = this.props.match.path
    switch(handle) {
      case '/curts':
          this.state.type = 'CURTS';
      break;
      case '/documentals':
          this.state.type = 'DOCS';
      break;
      case '/centres-educatius':
          this.state.type = 'CENTRES';
      break;
      case '/videoclips':
          this.state.type = 'VIDEOCLIPS';
      break;
      case '/jove':
        this.state.type = 'JOVES';
      break;
      case '/llargs':
      default:
          this.state.type = 'LLARGS';
      break;
    }

    // Get form structure & current edition from CONFIG
    this.state.formStructure = CONFIG[this.state.type];
    this.Edition = 'films/' + CONFIG.CURRENT_EDITION + '/' + this.state.type;

  }

  sendForm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

    let params = {}

    if (form.checkValidity() === false) {
      document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {
      params = {
        filmBand: this.inputFilmBand ? this.inputFilmBand.value : null,
        filmSchool: this.inputFilmSchool ? this.inputFilmSchool.value : null,
        filmSchoolLevel: this.inputFilmSchoolLevel ? this.inputFilmSchoolLevel.value : null,        
        filmSchoolTutor: this.inputFilmSchoolTutor ? this.inputFilmSchoolTutor.value : null,                
        filmCategory: this.inputFilmCategory ? this.inputFilmCategory.value : 'Sense categoría',
        filmName: this.inputFilmName ? this.inputFilmName.value : 'Sense títol',
        filmYear: this.inputFilmYear ? this.inputFilmYear.value : null,
        filmState: this.inputFilmState ? this.inputFilmState.value : null,
        filmCity: this.inputFilmCity ? this.inputFilmCity.value : null,
        filmCountry: this.inputFilmCountry ? this.inputFilmCountry.value : null,
        filmLanguage: this.inputFilmLanguage ? this.inputFilmLanguage.value : (this.inputFilmLangCAT ? ( (this.inputFilmLangCAT.checked ? 'Català' : '') + (this.inputFilmLangES.checked ? ', Castellà' : '') + (this.inputFilmLangEN.checked ? ', Anglès' : '') + (this.inputFilmLangOT.checked ? ', Altres.' : '') ) : null),
        filmTarragona: this.inputFilmTarragona ? this.inputFilmTarragona.checked : null,
        filmLangCAT: this.inputFilmLangCAT ? this.inputFilmLangCAT.checked : null,
        filmLangES: this.inputFilmLangES ? this.inputFilmLangES.checked : null,
        filmLangEN: this.inputFilmLangEM ? this.inputFilmLangEN.checked : null,
        filmLangOT: this.inputFilmLangOT ? this.inputFilmLangOT.checked : null,
        filmLength: this.inputFilmLength ? this.inputFilmLength.value : null,
        filmPremiere: this.inputFilmPremiere ? this.inputFilmPremiere.value : null,
        filmGenre: this.inputFilmGenre ? this.inputFilmGenre.value : null,
        filmFestivals: this.inputFilmFestivals ? this.inputFilmFestivals.value : null,
        filmDirector: this.inputFilmDirector ? this.inputFilmDirector.value : null,
        filmDirectorEmail: this.inputFilmDirectorEmail ? this.inputFilmDirectorEmail.value : null,
        filmActoring: this.inputFilmActoring ? this.inputFilmActoring.value : null,
        // filmSpecialEdition: this.inputFilmSpecialEdition ? this.inputFilmSpecialEdition.value : null,
        filmSynopsis: this.inputFilmSynopsis ? this.inputFilmSynopsis.value : null,
        filmLink: this.inputFilmLink ? this.inputFilmLink.value : null,
        filmPass: this.inputFilmPass ? this.inputFilmPass.value : null,
        imageCover: this.state.imageCover ? this.state.imageCover : null,
        imageFrame1: this.state.imageFrame1 ? this.state.imageFrame1 : null,
        imageFrame2: this.state.imageFrame2 ? this.state.imageFrame2 : null,
        imageFrame3: this.state.imageFrame3 ? this.state.imageFrame3 : null,
        producerName: this.inputProducerName ? this.inputProducerName.value : null,
        producerCompany: this.inputProducerCompany ? this.inputProducerCompany.value : null,
        producerSite: this.inputProducerSite ? this.inputProducerSite.value : null,
        contactName: this.inputContactName ? this.inputContactName.value : 'Sense Nom',
        contactRole: this.inputContactRole ? this.inputContactRole.value : null,
        contactEmail: this.inputContactEmail ? this.inputContactEmail.value : 'tpinel@fic-cat.cat',
        contactPhone: this.inputContactPhone ? this.inputContactPhone.value : null,
        contactNIF: this.inputContactNIF? this.inputContactNIF.value : null,
        contactBirth: this.inputContactBirth? this.inputContactBirth.value : null,
        contactAdress: this.inputContactAdress? this.inputContactAdress.value : null,
        acceptInfo: this.inputAcceptInfo && this.inputAcceptInfo.checked ? 'Si' : 'No',
        acceptLegal: this.inputAcceptLegal && this.inputAcceptLegal.checked ? 'Si' : 'No',
        acceptLegalOnline: this.inputAcceptLegalOnline && this.inputAcceptLegalOnline.checked ? 'Si' : 'No',
        acceptOnline: this.inputAcceptOnline && this.inputAcceptOnline.checked ? 'Si' : 'No',
        acceptOwn: this.inputAcceptOwn && this.inputAcceptOwn.checked ? 'Si' : 'No',
        message: this.inputMessage ? this.inputMessage.value : null
      };

      // STORE CONST FOR EMAIL
      const to = params.contactEmail;
      const toUs = "administradors@fic-cat.cat";
      const subject = params.filmName;
      const name = params.contactName;
      const film = params.filmName;
      const filmCategory = MESSAGES[this.state.type];

      console.log(to)
      console.log(toUs)
      console.log(subject)
      console.log(name)
      console.log(film)
      console.log(filmCategory)
      
      // WRITE ON DATABASE AND SEND EMAIL IF SUCCESS
      this.props.firebase.database().ref(this.Edition).push(params).then((params) => {
        
      const filmLink =  window.location.origin + ROUTES['LIST_'  + this.state.type];

        this.setState({ success: true });

        // EMAIL TO THE USER
        Axios.get(CONFIG.SEND_EMAIL_URL, {
          params: {
            to: to,
            subject: subject,
            name: name,
            film: film,
            filmCategory: filmCategory
          },
        });

        // EMAIL TO ADMINS
        Axios.get(CONFIG.SEND_EMAIL_URL, {
          params: {
            to: toUs,
            subject: subject,
            name: name,
            film: film,
            filmCategory: filmCategory,
            filmLink: filmLink
          },
        });

        
      }).catch(() => {
        this.setState({ success: false })
      });
    }
  }

  successMessage() {

    return (
      <div className="container">
        <div className='col-sm-6' style={{ margin: '0 auto'}}>
          <div className='card'>
            <div className="card-body">
              <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
              <h5 className="card-title">Gràcies per la teva inscripció!</h5>
              <p className="card-text">Hem rebut correctament la teva producció. Ens posarem en contacte amb tu per correu electrònic per comunicar el resultat de la selecció.</p>
              <Button href={ROUTES.LANDING} variant="success">Inscriure una altre producció</Button>
            </div>
            <img className='card-img-bottom' src={"/img/" + this.state.type.toLowerCase()  + ".jpg"} alt="FIC-CAT" />
          </div>
        </div>
      </div>
    );
  }

  imageUploadForm (){

    const handleUploadSuccess = (url, imageField) => {
      this.setState({
        [imageField]: url
      });
    }
    
    return (
      <div>
          <h2 style={{ margin: `20px 0 20px` }} >Cartell promocional i fotogrames</h2>
          <div className="row">
          { this.state.formStructure.indexOf('imageCover') !== -1 && (
            <div className="col-sm-6 pb-2">
              <dl>
                <dt>
                  Cartell (Màx. 5Mb)
                </dt>
                <dd>
                  <FileUploader
                  hidden
                  accept="image/*"
                  imageField='imageCover'
                  onUploadSuccess={handleUploadSuccess}
                />
                </dd>
              </dl>
            </div>
          )}
          { this.state.formStructure.indexOf('imageFrame1') !== -1 && (
            <div className="col-sm-6 pb-2">
              <dl>
                <dt>
                  Fotograma principal (Màx. 5Mb)
                </dt>
                <dd>
                  <FileUploader
                  hidden
                  accept="image/*"
                  imageField='imageFrame1'
                  onUploadSuccess={handleUploadSuccess}
                />
                </dd>
              </dl>
            </div>
          )}
          { this.state.formStructure.indexOf('imageFrame2') !== -1 && (
            <div className="col-sm-6">
              <dl>
                <dt>
                  Fotograma 2 (Màx. 5Mb)
                </dt>
                <dd>
                  <FileUploader
                  hidden
                  accept="image/*"
                  imageField='imageFrame2'
                  onUploadSuccess={handleUploadSuccess}
                />
                </dd>
              </dl>
            </div>
          )}
          { this.state.formStructure.indexOf('imageFrame3') !== -1 && (
            <div className="col-sm-6">
              <dl>
                <dt>
                  Fotograma 3 (Màx. 5Mb)
                </dt>
                <dd>
                  <FileUploader
                  hidden
                  accept="image/*"
                  imageField='imageFrame3'
                  onUploadSuccess={handleUploadSuccess}
                />
                </dd>
              </dl>
            </div>
          )}
        </div>
      </div>
    )
  } 

  contactInfoForm(){
    return (
      <div>
          <h2 style={{ margin: '40px 0 0' }}>Persona de contacte</h2>
          <p className="text-muted">Totes les comunicacions seran enviades a la persona designada com a contacte.</p>
          { this.state.formStructure.indexOf('contactName') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactName'>Nom de la persona de contacte</label>
            <input type='text' required className='form-control' id='contactName' placeholder='Ex: Joan Roig' ref={contactName => this.inputContactName = contactName} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
          { this.state.formStructure.indexOf('contactRole') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactRole'>Rol de la persona de contacte</label>
            <input type='text' required className='form-control' id='contactRole' placeholder='Ex: Director i Productor' ref={contactRole => this.inputContactRole = contactRole} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
          { this.state.formStructure.indexOf('contactNIF') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactNIF'>DNI de la persona de contacte</label>
            <input type='text' required className='form-control' id='contactNIF' placeholder='Ex: 12345678A' ref={contactNIF => this.inputContactNIF = contactNIF} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
          { this.state.formStructure.indexOf('contactBirth') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactBirth'>Data de naixement de la persona de contacte</label>
            <input type='date' required className='form-control' style={{width:'200px'}} id='contactBirth' ref={contactName => this.inputContactBirth = contactBirth} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
          { this.state.formStructure.indexOf('contactEmail') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactEmail'>Correu electrònic de la persona de contacte:</label>
            <input type='email' required className='form-control' id='contactEmail' placeholder='Ex: jroig@exemple.com' ref={contactEmail => this.inputContactEmail = contactEmail} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
          { this.state.formStructure.indexOf('contactPhone') !== -1 && (
          <div className='form-group'>
            <label htmlFor='contactPhone'>Número de mòbil de la persona de contacte</label>
            <input type='number' required className='form-control' id='contactPhone' placeholder='555 55 55 55' ref={contactPhone => this.inputContactPhone = contactPhone} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
          )}
        </div>
    )
  }

  filmInfoForm () {
    return (
      <>
        { this.state.type !== 'JOVES' && (
          <h2 style={{ margin: `40px 0` }}>Dades de l'obra</h2>
        )}
        { this.state.formStructure.indexOf('filmCategory') !== -1 && (               
        <Form.Group controlId="filmCategory">
          <Form.Label>Modalitat</Form.Label>
          <Form.Control as="select"
            required
            onChange={ (event) => { this.setState({ category: event.target.value }) } }
            ref={filmCategory => this.inputFilmCategory = filmCategory}
          >
            <option value=''>Selecciona una opció...</option>
            <option value='ficcio'>Ficció</option>
            <option value='documental'>Documental</option>
            <option value='animacio'>Animació</option>
            <option value='videocreacio'>Videocreació</option>
            <option value='altres'>Altres</option>                  </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { (this.state.formStructure.indexOf('filmGenre') !== -1 && this.state.category === undefined) ||
         (this.state.category === 'ficcio' ||  this.state.category === 'animacio') && (
        <Form.Group controlId="filmState">
          <Form.Label>Gènere</Form.Label>
          <Form.Control as="select"
            required
            ref={filmGenre=> this.inputFilmGenre = filmGenre}
          >
            <option value=''>Selecciona una opció...</option>
            { CONFIG.GENRES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
          </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmBand') !== -1 && (
        <Form.Group controlId="filmBand">
          <Form.Label>Intèrpret(s) / Grup de l'obra musical</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Ex: La Trinca'
            ref={filmBand => this.inputFilmBand = filmBand}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmSchool') !== -1 && (
        <Form.Group controlId="filmSchool">
          <Form.Label>Nom del centre educatiu</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Ex: Escola Exemple'
            ref={filmSchool => this.inputFilmSchool = filmSchool}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmSchoolLevel') !== -1 && (               
        <Form.Group controlId="filmSchoolLevel">
          <Form.Label>Nivell educatiu dels autors de l'obra</Form.Label>
          <Form.Control as="select"
            required
            ref={filmSchoolLevel => this.inputFilmSchoolLevel = filmSchoolLevel}
          >
            <option value=''>Selecciona una opció...</option>
            <option value='infantil'>Educació infantil</option>
            <option value='primaria'>Educació primària</option>
            <option value='secundaria'>Educació secundària obligatòria</option>
            <option value='batxillerat'>Batxillerat i cicles formatius</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmName') !== -1 && (
        <Form.Group controlId="filmName">
          <Form.Label>Títol de la producció</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Ex: La gran pel·lícula'
            ref={filmName => this.inputFilmName = filmName}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmYear') !== -1 && (
        <Form.Group controlId="filmYear">
          <Form.Label>Any de producció (Màxima antiguitat acceptada: {CONFIG.CURRENT_EDITION-1}) </Form.Label>
          <Form.Control
            required
            type='number'
            placeholder={CONFIG.CURRENT_EDITION}
            min={(Math.round(CONFIG.CURRENT_EDITION)) - 1}
            max={Math.round(CONFIG.CURRENT_EDITION)}
            ref={filmYear => this.inputFilmYear = filmYear}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('contactAdress') !== -1 && (
        <Form.Group controlId="contactAdress">
          <Form.Label>Adreça postal</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Ex: El meu carrer, 1-2'
            ref={contactAdress => this.inputContactAdress = contactAdress}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmCountry') !== -1 && (
        <Form.Group controlId="filmCountry">
          <Form.Label>País</Form.Label>
          <Form.Control as="select"
            required
            ref={filmCountry => this.inputFilmCountry = filmCountry}
            defaultValue={this.state.country}
            onChange={ (event) => { this.setState({ country: event.target.value }) } }
          >
            <option value=''>Selecciona una opció...</option>
            { CONFIG.COUNTRIES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
          </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmState') !== -1 && this.state.country !== 'Espanya' && (
        <Form.Group controlId="filmState">
          <Form.Label>Província</Form.Label> 
          <Form.Control
            required
            type='text'
            placeholder='Ex: Tarragona'
            ref={filmState => this.inputFilmState = filmState}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmState') !== -1 && this.state.country === 'Espanya' && (
        <Form.Group controlId="filmState">
          <Form.Label>Província</Form.Label>
          <Form.Control as="select"
            required
            ref={filmState => this.inputFilmState = filmState}
            defaultValue={this.state.state}
            onChange={ (event) => { this.setState({ state: event.target.value }) } }
          >
            <option value=''>Selecciona una opció...</option>
            { CONFIG.SPAIN_STATES.map((t,k) => <option key={k} value={t}>{t}</option>) } 
          </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmCity') !== -1 && (
        <Form.Group controlId="filmCity">
          <Form.Label>Localitat</Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Ex: Roda de Berà'
            ref={filmCity => this.inputFilmCity = filmCity}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmLength') !== -1 && (
        <Form.Group controlId="filmLength">
          <Form.Label>Durada en minuts</Form.Label>
          <Form.Control
            required
            type='number'
            placeholder='Ex: 120'
            ref={filmLength => this.inputFilmLength = filmLength}
          />
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmPremiere') !== -1 && (               
        <Form.Group controlId="filmPremiere">
          <Form.Label>L'estrena serà la projecció al FIC-CAT? (en cas de selecció)</Form.Label>
          <Form.Control as="select"
            required
            ref={filmPremiere => this.inputFilmPremiere = filmPremiere} onChange={ (event) => { event.target.value ==='Estrena' ? this.setState({ premiere: true }): this.setState({ premiere: false }) } }
          >
            <option value=''>Selecciona una opció...</option>
            <option value='Estrena'>Si, L'obra no ha estat estrenada prèviament.</option>
            <option value='Festivals'>No, L'obra ha estat estrenada prèviament a festivals.</option>
            <option value='Cinema'>No, L'obra ha estat estrenada prèviament en sales de cinema i/o festivals.</option>
            <option value='TV'>No, L'obra ha estat estrenada prèviament a la televisió i/o festivals i/o sales de cinema.</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
        </Form.Group>
        )}
        { !this.state.premiere && (
          <Form.Group controlId="filmFestivals">
            <Form.Label><strong>Presència en festivals i premis.</strong><br/> Indica aquí en quins festivals s'ha projectat i quins premis ha rebut el treball.</Form.Label>
            <Form.Control as="textarea" rows="3" ref={filmFestivals => this.inputFilmFestivals = filmFestivals} />
          </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmLangCAT') !== -1 && (
        <Form.Group controlId="filmLangCAT">
          <h2 style={{ margin: `60px 0 20px` }} >Llengua V.O.</h2>
          <Form.Label><strong>Aquest festival només accepta produccions que siguin totalment o parcial en català.</strong> <br/ >Si us plau, indica en quina llengua o llengües ha estat rodada la producció en la seva versió original:</Form.Label>
          <Form.Check>
            <Form.Check.Input
              required
              id="filmLangCAT"
              ref={filmLangCAT => this.inputFilmLangCAT = filmLangCAT}
            />
            <Form.Check.Label htmlFor="filmLangCAT">Català (Requisit indispensable)</Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_CAT }</Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmLangES') !== -1 && (
        <Form.Group controlId="filmLangES">
        <Form.Check>
          <Form.Check.Input
            id="filmLangES"
            ref={filmLangES => this.inputFilmLangES = filmLangES}
          />
          <Form.Check.Label htmlFor="filmLangES">Castellà</Form.Check.Label>
        </Form.Check>
      </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmLangEN') !== -1 && (
        <Form.Group controlId="filmLangEN">
        <Form.Check>
          <Form.Check.Input
            id="filmLangEN"
            ref={filmLangEN => this.inputFilmLangEN = filmLangEN}
          />
          <Form.Check.Label htmlFor="filmLangEN">Anglès</Form.Check.Label>
        </Form.Check>
      </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmLangOT') !== -1 && (
        <Form.Group controlId="filmLangOT">
        <Form.Check>
          <Form.Check.Input
            id="filmLangOT"
            ref={filmLangOT => this.inputFilmLangOT = filmLangOT}
          />
          <Form.Check.Label htmlFor="filmLangOT">Altres</Form.Check.Label>
        </Form.Check>
      </Form.Group>
        )}
        { this.state.formStructure.indexOf('filmTarragona') !== -1 && (
        <Form.Group controlId="filmTarragona">
          <Form.Label><h2 style={{ margin: `40px 0 20px` }} >Candidatura Comarques Tarragonines</h2></Form.Label>
          <Form.Check>
            <Form.Check.Input
              id="filmTarragona"
              ref={filmTarragona => this.inputFilmTarragona = filmTarragona}
            />
            <Form.Check.Label htmlFor="filmTarragona">Confirmo que <strong>aquesta producció pot optar al premi a la Millor Producció de les Comarques Tarragonines</strong> perquè compleix aquests dos criteris:</Form.Check.Label>
            <p>
          - Que l’equip de producció i/o direcció és provinent de les Comarques Tarragonines.<br />
          - Que la producció ha estat rodada totalment o parcial a les Comarques Tarragonines.</p>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_CAT }</Form.Control.Feedback>
          </Form.Check>
        </Form.Group>
        )}
      </>
    )
  }

  filmCastForm () {
    return (
      <>
        { this.state.formStructure.indexOf('filmDirector') !== -1 && (
          <h2 style={{ margin: `60px 0 20px` }} >Fitxa tècnica i artística</h2>
          )}
          { this.state.formStructure.indexOf('filmSchoolTutor') !== -1 && (
          <Form.Group controlId="filmSchoolTutor">
            <Form.Label>Nom del tutor/a responsable de l'obra</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ex: Carol Reig'
              ref={filmSchoolTutor => this.inputFilmSchoolTutor = filmSchoolTutor}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('filmDirector') !== -1 && (
          <Form.Group controlId="filmDirector">
            <Form.Label>Director/a</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ex: Joana Roig'
              ref={filmDirector => this.inputFilmDirector = filmDirector}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('filmDirectorEmail') !== -1 && (
          <Form.Group controlId="filmDirectorEmail">
            <Form.Label>E-mail del director/a</Form.Label>
            <Form.Control
              required
              type='email'
              placeholder='Ex: joan@exemple.cat'
              ref={filmDirectorEmail => this.inputFilmDirectorEmail = filmDirectorEmail}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('producerCompany') !== -1 && (
          <Form.Group controlId="producerCompany">
            <Form.Label>Empresa productora</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ex: Produccions exemplars'
              ref={producerCompany => this.inputProducerCompany = producerCompany}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('producerSite') !== -1 && (
          <Form.Group controlId="producerSite">
            <Form.Label>Web de la productora</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ex: produccionsexemplars.cat'
              ref={producerSite => this.inputProducerSite = producerSite}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('producerName') !== -1 && (
          <Form.Group controlId="producerName">
            <Form.Label>Nom del productor/a</Form.Label>
            <Form.Control
              type='text'
              placeholder='Ex: Lucia Vilalta'
              ref={producerName => this.inputProducerName = producerName}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          { this.state.formStructure.indexOf('filmActoring') !== -1 && (
          <Form.Group controlId="filmActoring">
            <Form.Label>Intèrprets principals</Form.Label>
            <Form.Control
              as='textarea'
              required
              rows="3"
              placeholder='Ex: Júlia Roig, Pere Verd....'
              ref={filmActoring => this.inputFilmActoring = filmActoring}
            >
            </Form.Control>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
          {/* {this.state.formStructure.indexOf('filmSpecialEdition') !== -1 && (
          <Form.Group controlId="filmSpecialEdition">
            <Form.Label>Muntador/a</Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ex: Júlia Roig'
              ref={filmSpecialEdition => this.inputFilmSpecialEdition = filmSpecialEdition}
            />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )} */}
          { this.state.formStructure.indexOf('filmSynopsis') !== -1 && (
            <Form.Group controlId="filmSynopsis">
            <Form.Label>Sinopsi (inferior a 350 caràcters)</Form.Label>
            <Form.Control
              as='textarea'
              required
              rows='3'
              maxLength='350'
              placeholder='Ex: Un noi coneix a una noia i....'
              ref={filmSynopsis => this.inputFilmSynopsis = filmSynopsis}
            >
            </Form.Control>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </Form.Group>
          )}
      </>
    )
  }

  filmLinkForm () {
    return (
      <>
        { (this.state.formStructure.indexOf('filmLink') !== -1 &&
          this.state.formStructure.indexOf('filmPass') !== -1 ) && (
        <div>
          <h2 style={{ margin: `60px 0 20px` }}>Dades de visionat</h2>
          <p>Enllaç en streaming per a la selecció oficial.
L'enllaç ha d'estar disponible per a la seva visualització en línia (streaming) i per permetre la descàrrega.
L'arxiu enviat no ha de caducar; ha d'estar disponible fins al començament de l'edició del FIC-CAT {MESSAGES.FICCAT_DATE}.
En cas que la vostra producció entri a la Selecció Oficial, es requerirà un format de projecció en alta definició (DCP / Fitxer digital).</p>

          <div className='form-group'>
            <label htmlFor='filmLink'>Enllaç per al visionat del comitè de selecció (Vimeo, Youtube o Google Drive)</label>
            <input type='text' required className='form-control' id='filmLink' placeholder='Ex: http://vimeo.com/exemple' ref={filmLink => this.inputFilmLink = filmLink} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>

          <div className='form-group'>
            <label htmlFor='filmPass'>Contrassenya per al visionat</label>
            <input type='text' required className='form-control' id='filmPass' placeholder='Ex: 1234' ref={filmPass => this.inputFilmPass = filmPass} />
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_REQUIRED }</Form.Control.Feedback>
          </div>
        </div>
      )}
      </>
    )
  } 

  legalInfoForm(){

    const handleCheckboxChange = (e) => {
      if ( e.target.checked ) {
        this.value = 'Si'
      } else {
        this.value = 'No'
      }
    };

    return (
      <div>
        <h2 style={{ margin: `60px 0 20px` }}>Acceptació de bases i cessió de drets</h2>
        <div className='form-group'>
          <Form.Check>
            <Form.Check.Input
              required
              id="acceptLegal"
              onChange={handleCheckboxChange}
              ref={acceptLegal => this.inputAcceptLegal = acceptLegal}
            />
            <Form.Check.Label htmlFor="acceptLegal">Requerit: <strong>Accepto <a href={'/pdf/bases_' + CONFIG.CURRENT_EDITION + '.pdf'} target='blank'>les bases</a></strong> de la present edició del FIC-CAT i certifico que la informació proveïda en aquest formulari és certa.</Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        {/*
        <div className='form-group'>
          <Form.Check>
            <Form.Check.Input
              id="acceptLegalOnline"
              onChange={handleCheckboxChange}
              ref={acceptLegalOnline => this.inputAcceptLegalOnline = acceptLegalOnline}
            />
            <Form.Check.Label>Accepto <a href={'/pdf/cessio_' + (Math.round(CONFIG.CURRENT_EDITION) + 1) + '.pdf'} target='blank'>condicions de cessió de drets online</a> per a que la producció formi part de la selecció online a TV3 a la carta i opti al premi del públic online. (Opcional)</Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        */}
        { this.state.formStructure.indexOf('acceptOwn') !== -1 && (
        <div className='form-group'>
        <Form.Check>
            <Form.Check.Input
              id="acceptOwn"
              required
              onChange={handleCheckboxChange}
              ref={acceptOwn => this.inputAcceptOwn = acceptOwn}
            />
            <Form.Check.Label htmlFor="acceptOwn">Requerit: <strong>Sóc propietari/a dels drets d'exhibició</strong> d'aquesta obra i/o estic autoritzat/da per a la seva distribució i exhibició. </Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        )}
        { this.state.formStructure.indexOf('acceptOnline') !== -1 && (
        <div className='form-group'>
          <Form.Check>
            <Form.Check.Input
              id="acceptOnline"
              onChange={handleCheckboxChange}
              ref={acceptOnline => this.inputAcceptOnline = acceptOnline}
            />
            <Form.Check.Label htmlFor="acceptOnline">Opcional: Estic interessat/da en optar al <strong>premi del públic online</strong> que requerirà posar la meva producció online durant els dies del Festival {MESSAGES.FICCAT_DATE} en una plataforma com <strong>TV3 a la carta</strong> o similar (En cas d'estar interessat/da i formar part de la selecció oficial et contactarem per concretar la cessió de drets).</Form.Check.Label>
            <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_ACCEPT }</Form.Control.Feedback>
          </Form.Check>
        </div>
        )}
        {/*
        <div className='form-group'>
          <label htmlFor='acceptInfo'><input onChange={handleCheckboxChange} type='checkbox' className='form-control' id='acceptInfo' style={{ width: '16px', height: '16px', display: 'inline-block' }} ref={acceptInfo => this.inputAcceptInfo = acceptInfo} /> Vull rebre informació sobre el festival per correu electrònic. (Opcional)</label>
        </div>
        */}
      </div>
    )
  } 

  render () {
    return (
      <div>
        { (CONFIG.SIGNIN_FILMS || ( CONFIG.SIGNIN_YOUNG && this.state.type === 'JOVES' ) || (this.authUser !== null && CONFIG.SIGNIN_FILMS_USER === true) || (this.overRideAccess === true) ) ? (
        <>
        { this.state.success === true && ( 
          this.successMessage()
        )}
        { this.state.success !== true && (
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
                <h1 className="h2" style={{ display:'block' }}> <strong>Inscripció { MESSAGES[this.state.type] } FIC-CAT {CONFIG.CURRENT_EDITION}</strong></h1>
                <p className="text-muted">{ MESSAGES[this.state.type + '_FORM_DESC'] }</p>
                {/* Render IMAGE UPLOAD form if is defined in form structure */}
                { (this.state.formStructure.indexOf('imageCover') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame1') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame2') !== -1 ||
                      this.state.formStructure.indexOf('imageFrame3') !== -1 ) && (
                  this.imageUploadForm()
                )}
                <Form noValidate validated={ this.state.validated } id="formFilm" onSubmit={this.sendForm.bind(this)} ref='contactForm' >
                  {/* Render CONTACT info form if is defined in form structure */}
                  { (this.state.formStructure.indexOf('contactName') !== -1 ||
                      this.state.formStructure.indexOf('contactRole') !== -1 ||
                      this.state.formStructure.indexOf('contactEmail') !== -1 ||
                      this.state.formStructure.indexOf('contactNIF') !== -1 ||
                      this.state.formStructure.indexOf('contactBirth') !== -1 ||
                      this.state.formStructure.indexOf('contactPhone') !== -1 ) && (
                    this.contactInfoForm()
                  )}
                  {/* Render FILM INFO form if is defined in form structure */}
                  { this.state.testSubmit !== true && (
                    this.filmInfoForm()
                  )}
                  {/* Render FILM CAST form if is defined in form structure */}
                   { this.state.testSubmit !== true && (
                    this.filmCastForm()
                  )}
                  {/* Render FILM LINK form if is defined in form structure */}
                  { this.state.testSubmit !== true && (
                    this.filmLinkForm()
                  )}
                  { this.legalInfoForm() }
                  <div className='form-group'>
                    <label htmlFor='message'>Vols afegir alguna informació o suggeriment?</label>
                    <textarea className='form-control' id='message' rows='3' ref={message => this.inputMessage = message}></textarea>
                  </div>
                  <button type='submit' className='btn btn-lg btn-success'><strong>Inscriure producció</strong></button>
                </Form>
              </div>
            </div>
          </div>
        )}
        </>
        ) : ( 
          <div>
            <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
            <h2 className="mb-3">{MESSAGES.SIGIN_FALSE_TITLE}</h2>
            <Alert variant='warning' >{MESSAGES.SIGIN_FALSE_DESC}</Alert>
          </div>
        )}
      </div>
    )
  }
}



export default compose(
  withFirebase
)(FilmsForm);
