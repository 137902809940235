import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash';

import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';

import { Link } from 'react-router-dom';

import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';

let filmType = '';
let authUser = JSON.parse(localStorage.getItem('authUser'));


class FilmListPage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.type = 'LLARGS';
        break;
    }
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
        <h1>{ MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</h1>
        <p>{ MESSAGES[this.state.type + '_FORM_DESC'] }</p>
        { this.state.type === 'CENTRES' && (
          <>
            <span>Curs: </span>
            { window.location.pathname === ROUTES.LIST_CENTRES ? (
              <strong>&#x27A4; Tots</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES }>Tots</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.LIST_CENTRES + '/primaria' ? (
              <strong>&#x27A4; Primaria</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES + '/primaria' }>Primaria</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.LIST_CENTRES + '/secundaria' ? (
              <strong>&#x27A4; Secundaria</strong>
            ) : (
              <Link to={ ROUTES.LIST_CENTRES + '/secundaria' }>Secundaria</Link>
            )}
            &nbsp;
          <br/><br/>
          </>
        )}
        <Films />
      </div>
    )
  }
}

const FilmList = ({ films }) => (
  <tbody>
    {films.map(film => (
      <FilmItemFirebase key={film.uid} film={film} />
    ))}
  </tbody>
);

class FilmItem extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      firebase: props.firebase,
      film: props.film,
    }

  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + this.authUser.uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  render () {
    const { film, userFilm } = this.state
    const currentYear = new Date().getFullYear()
    return (
      <React.Fragment>
        { (authUser.roles['ADMIN'] || (!authUser.roles['ADMIN'] && film.isSelectable)) && (
          <tr style={ userFilm && (userFilm.userFilmRank > 0) ? { background: 'rgba(40,167,69,.3)' } : {} }>
            <td style={{ textAlign: 'center'}}>{film.filmLength}'</td>
            <td>
              <strong><a href={ROUTES['ITEM'] + '/' + filmType + '/' + film.uid }>{film.contactBirth === undefined ? film.filmName : (film.contactName + ' (' +  (currentYear - film.contactBirth.split('-')[0]) + ')') }</a></strong><br/>
              {film.contactBirth !== undefined && (
                <>
                  <small>{film.contactBirth}<br /></small>
                  <span>{film.filmName} <br/> </span>
                </>
              )}
              <strong className="h1 text-secondary">{ userFilm && (userFilm.userFilmRank > 0) ? userFilm.userFilmRank : '-'}</strong>
              </td>
            <td style={{ width: '50%'}}>{ userFilm && (userFilm.userFilmNote !== '') ? userFilm.userFilmNote : '-'}</td>
            <td className='d-none d-md-table-cell'>
              <span className={"badge " + (film.acceptOnline && film.acceptOnline === 'Si' ? 'badge-success' : 'badge-danger')}>ONLINE {film.acceptOnline ? film.acceptOnline.toUpperCase() : ''}</span>&nbsp; 
              <span className={"badge " + (film.filmPremiere && film.filmPremiere === 'Estrena' ? 'badge-success' : 'badge-secondary')}>{film.filmPremiere ? film.filmPremiere.toUpperCase() : ''}</span>
              <br/>
              { (film.filmTarragona !== undefined && film.filmTarragona === true) || ( film.filmTarragona === undefined  && film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 ) ? (
                <span className="badge badge-warning">CANDITAT CT:{film.filmState}</span>
              ) : (
                <small>{film.filmState}</small>
              )}
              <br />
              { film.filmSchool &&
                <>
                <strong className="text-muted">{film.filmSchool} ({film.filmSchoolLevel})</strong><br/>
                </>
              }
              <small>Director/a: {film.filmDirector}<br/>Intèrprets: {film.filmActoring}</small></td>
            { authUser.roles['ADMIN'] && 
             <td className={film.isSelectable === undefined ? 'bg-warning' : (film.isSelectable === false ? 'bg-danger' : 'bg-success') }></td>
            }
          </tr>
        )}
      </React.Fragment>
    )
  }
}

const FilmItemFirebase = withFirebase(FilmItem);

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      films: [],
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.filmTypeName = 'JOVE';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.films();
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }

  UpdateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        // convert messages list from snapshot

        let filmListFiltered = {};
        switch(window.location.pathname.split('/')[3]) {
          case 'secundaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' ) } );
          break;
          case 'primaria':
            filmListFiltered = _.filter( filmList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
          break;
          default:
            filmListFiltered = filmList;
          break;
        }

        this.setState({
          films: filmListFiltered.reverse(),
          loading: false,
        });
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    this.UpdateFilmList();
  }

  UNSAFE_componentWillReceiveProps() {
    this.UpdateFilmList();
  }
 
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            {loading &&
              <React.Fragment>
                  <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
              </React.Fragment>
            }
            {!loading &&
             <div className="row">
              <dl className="col-sm-3">
                <dt>Acceptades:</dt>
                <dd className="h1 text-success">{this.getFilmsByIsSelectable(films, true).length}</dd>
              </dl>
              <dl className="col-sm-3">
                <dt>Pendents de revisió:</dt>
                <dd className="h1 text-warning">{this.getFilmsByIsSelectable(films, undefined).length}</dd>
              </dl>
              <dl className="col-sm-3">
                <dt>No acceptades:</dt>
                <dd className="h1 text-danger">{this.getFilmsByIsSelectable(films, false).length}</dd>
              </dl>
              <dl className="col-sm-3">
                <dt>Inscrites:</dt>
                <dd className="h1 text-secondary">{films ? films.length : '0'}</dd>
              </dl>
            </div>
            }
            {!loading && 
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center'}}>Duració</th>
                  <th>Nom / Nota</th>
                  <th>Notes</th>
                  <th className='d-none d-md-table-cell'>Fitxa artística</th>
                  { authUser.roles['ADMIN'] && 
                    <th></th>
                  }
                </tr>
              </thead>
                <FilmList films={films} />
            </Table>
            }
          </React.Fragment>
        ) : (
          <Alert variant="warning">No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(FilmListPage);