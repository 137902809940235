export const FICCAT_DATE = "(Del 1 al 8 de juny de 2024)";
export const LLARGS = 'Llargmetratges';
export const LLARGS_FORM_DESC = "Ficció, animació i experimental de durada superior als 30 minuts.";
export const DOCS = 'Documentals';
export const DOCS_FORM_DESC = "Obres documentals de durada superior als 30 minuts.";
export const CURTS = 'Curtmetratges';
export const CURTS_FORM_DESC = "Ficció, animació, documental o experimental de durada inferior als 30 minuts.";
export const CENTRES = 'Centres educatius';
export const CENTRES_FORM_DESC = "Produccions audiovisuals de Centres educatius (primària i secundària) de durada inferior als 10 minuts.";
export const VIDEOCLIPS = 'Videoclips';
export const VIDEOCLIPS_FORM_DESC = "Vídeos musicals de durada inferior als 10 minuts.";
export const JOVES = 'Jove 48 Hores';
export const JOVES_FORM_DESC = "Inscripció per al concurs Jove amb la presentació d'un curtmetratge de temàtica i gènere lliures, de durada inferior als 3 minuts, crèdits inclosos en 48 hores.";
export const SIGNIN_FILM = "Inscriure";
export const FIELD_ACCEPT = "Per continuar has d'acceptar aixó.";
export const FIELD_REQUIRED = 'Si us plau, omple correctament aquest camp';
export const FIELD_UNSELECTED = 'Si us plau, selecciona una opció';
export const FIELD_CAT = 'Només acceptem produccions parcial o totalment en català';
export const SIGIN_FALSE_TITLE = "Inscripcions tancades"
export const SIGIN_FALSE_DESC = "Gràcies pel teu interès, però ara mateix les inscripcions estan tancades i no acceptem més produccions. Segueix les nostres xarxes socials per saber quan obrim inscripcions per a la propera edició.";