import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import * as serviceWorker from './serviceWorker';

import store from './store';
import App from './components/Router/app';
import Landing from './components/Router/landing';
import * as ROUTES from './constants/routes';

import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
        {window.location.pathname != ROUTES.APP_QR && window.location.pathname != ROUTES.TV3_QR && (
          <App />
        ) || (
        <div className='container' style={{ padding: '20px', maxWidth: '1260px', margin: '0 auto'  }}>
            <Landing />
        </div>
        )}
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
