import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import Form from 'react-bootstrap/Form'
import FileUploader from '../FileUploader';
import * as CONFIG from '../../constants/config';
import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';

//import FilmList from '../Films';

let filmType = '';
let filmUid = '';

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    filmUid = props.match.params.uid ? props.match.params.uid : null;
    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    this.state = {
      type: null,
      validated: null
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
    }
    this.Edition = CONFIG.CURRENT_EDITION + '_' + this.state.type;
  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + this.authUser.uid + '/' + filmUid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
         { (!this.authUser.roles['JURAT']) ? (
          <p><a href={ROUTES['LIST_' + this.state.type]}> &larr; Tornar al llistat de { MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</a></p>
         ) : (
          <p><a href={ROUTES['CHOICE_' + this.state.type]}> &larr; Tornar al llistat de { MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</a></p>
         )}
        {this.state.userFilm && 
          <Films userFilm={this.state.userFilm} />
        }
        {!this.state.userFilm &&
          <React.Fragment>
              <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
          </React.Fragment>
        }
      </div>
    )
  }
}

class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      userFilm: props.userFilm,
      loading: true,
      film: null,
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.short(filmUid);
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.doc(filmUid);
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.school(filmUid);
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.music(filmUid);
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.young(filmUid);
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.API = this.props.firebase.film(filmUid);
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  componentDidMount() {
    this.setState({ loading: true });

    this.state.API.once('value', snapshot => {
      
      if (snapshot) {
        // convert messages list from snapshot
        this.setState({
          film: snapshot.val(),
          loading: false,
        });

        if (this.state.film && (this.state.film.filmLink || this.state.film.eventLinkYouTube)) {
          // - Supported YouTube URL formats:
          //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
          //   - http://youtu.be/My2FRPA3Gf8
          //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
          // - Supported Vimeo URL formats:
          //   - http://vimeo.com/25451551
          //   - http://player.vimeo.com/video/25451551
          // - Also supports relative URLs:
          //   - //player.vimeo.com/video/25451551
    
          let filmEmbed = this.state.film.eventLinkYouTube != undefined ? this.state.film.eventLinkYouTube : this.state.film.filmLink; 
      

          if ( filmEmbed.indexOf('drive.google.com') > -1) {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|drive.google\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|file\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?\/([A-Za-z0-9._%-]*)(\S+)?/);
            this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$8 } })
          } else if (filmEmbed.indexOf('youtu.be') > -1) {
            this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: filmEmbed.split('/')[3] } })  
          } else {
            filmEmbed.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);
            if (RegExp.$3.indexOf('vimeo') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'vimeo' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('drive.google.com') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'drive' ,filmLinkId: RegExp.$6 } })
            } else if (RegExp.$3.indexOf('youtube') > -1) {
              this.setState( { film: {...this.state.film, filmLinkType:'youtube' ,filmLinkId: RegExp.$6 } })
            }
          }
        }
      } else {
        this.setState({ film: null, loading: false });
      }

      this.setState({ 
        isSelectable: this.state.film.isSelectable,
        isSelected: this.state.film.isSelected
      });

    });
  }

  handleUploadSuccess = (url, imageField) => {

    let data = this.state.film;

    data[imageField] = url;

    this.setState({
      [imageField]: url
    });

    if (data.filmName && data[imageField]) {
      this.state.API.set(data);    
    }
    
  }

  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }

  updateName(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data.filmName = e.target.value.toString();
      this.setState({ filmName: e.target.value});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
  }

  updateLink(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data.filmLink = e.target.value.toString();
      this.setState({ filmLink: e.target.value});

    }
    if (data.filmLink) {
      this.state.API.set(data);    
    }
  }

  updatePass(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data.filmPass = e.target.value.toString();
      this.setState({ filmPass: e.target.value});

    }
    if (data.filmPass) {
      this.state.API.set(data);    
    }
  }

  updateIsSelectable(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isSelectable'] = JSON.parse(e.target.value);
      this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    this.state.API.set(data);    
  }

  updateIsOnline(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isOnline'] = JSON.parse(e.target.value);
      this.setState({ isOnline: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
  }


  updateIsOffline(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isOffline'] = JSON.parse(e.target.value);
      this.setState({ isOffline: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
  }

  updateLength(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['filmLengthFixed'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.filmLengthFixed) {
      this.state.API.set(data);    
    }
  }

  updateEventDate(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventDate'] = e.target.value;
    }
    if (data.filmName && data.eventDate) {
      this.state.API.set(data);    
    }
  }


  updateShortGroup(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['shortGroup'] = e.target.value;
    }
    if (data.filmName && data.shortGroup) {
      this.state.API.set(data);    
    }
  }
  
  updateHideSelected(e) {

    let data = this.state.film;

    if ( e.target ) {
      data['hideSelected'] = e.target.checked ? true : false;
    }
    this.state.API.set(data); 
  }
  
  updateEventTime(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventTime'] = e.target.value;
    }
    if (data.filmName && data.eventTime) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkTv3(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkTv3'] = e.target.value;
    }
    if (data.filmName && data.eventLinkTv3) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkYouTube(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkYouTube'] = e.target.value;
    }
    if (data.filmName && data.eventLinkYouTube) {
      this.state.API.set(data);    
    }
  }

  updateEventLinkFilmin(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['eventLinkFilmin'] = e.target.value;
    }
    if (data.filmName && data.eventLinkTv3) {
      this.state.API.set(data);    
    }
  }

  updateAppTechnicalCast(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['appTechnicalCast'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.appTechnicalCast) {
      this.state.API.set(data);    
    }
  }

  updateAppShortDescription(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['appShortDescription'] = e.target.value;
      // this.setState({ isSelectable: JSON.parse(e.target.value)});

    }
    if (data.filmName && data.appShortDescription) {
      this.state.API.set(data);    
    }
  }



  
  updateIsSelected(e) {

    let data = this.state.film;
    
    if ( e.target.value ) {
      data['isSelected'] = JSON.parse(e.target.value);
      this.setState({ isSelected: JSON.parse(e.target.value)});

    }
    if (data.filmName) {
      this.state.API.set(data);    
    }
    // this.state.API.set(data);
  }

  sendUserFilm(e) {
   
    e.preventDefault();

    const form = e.currentTarget;
    this.setState({ validated: 'validated'});

    if (form.checkValidity() === false) {
      // document.documentElement.scrollTop = 0;
      e.stopPropagation();
    } else {
      const params = {
        userFilmRank: this.inputUserFilmRank ? Math.round(this.inputUserFilmRank.value) : null,
        userFilmNote: this.inputUserFilmNote ? this.inputUserFilmNote.value : null
      };
      
      this.props.firebase.database().ref( CONFIG.USER_NOTES + this.authUser.uid + '/' + filmUid).set(params).then(() => {
        // this.showAlert('success', 'Moltes gràcies per inscriure la teva producció al FIC-CAT. Molta sort!');
      }).catch(() => {
        // this.showAlert('danger', 'Hi ha algun error al formulario, si us plau repasa-ho.');
      });
      // this.resetForm();
    }
  }

  render() {
    const { film, userFilm } = this.state;
    return (
      <div>
        {film ? (
          <div className="row">
              <div className='col-sm-8'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row'>
                        <div className="col-sm-8">
                        <span className={"badge " + (film.acceptOnline === 'Si' ? 'badge-success' : 'badge-danger')}>ONLINE {film.acceptOnline ? film.acceptOnline.toUpperCase() : ''}</span>&nbsp; 
                          <span className={"badge " + (film.filmPremiere === 'Estrena' ? 'badge-success' : 'badge-secondary')}>{film.filmPremiere ? film.filmPremiere.toUpperCase(): ''}</span>&nbsp;
                          { ((film.filmTarragona !== undefined && film.filmTarragona === true) || ( film.filmTarragona === undefined  && film.filmState && film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 )) && (
                          <span className="badge badge-warning">CANDITAT CT:{film.filmState}</span>
                          )}
                       { film.filmCategory.toUpperCase() !== 'SENSE CATEGORÍA' && (
                        <h4>
                          <span className="text-muted"> { film.filmCategory.toUpperCase() }</span>
                        </h4>
                        )}
                        { !this.authUser.roles['EDITOR'] && (
                          <h1>{film.filmName}</h1>
                        ) || (
                        <Form style={{ display: 'block', overflow: 'hidden' }} id="filmNameForm" ref='filmNameForm'>
                          <div className='form-group '>
                            <label htmlFor='filmName' style={{ padding: '0', display: 'block' }}><input name="filmName" onBlur={this.updateName.bind(this)} type='input' defaultValue={film.filmName ? film.filmName : '' } style={{ width: '100%', display: 'block', fontSize: '20px', fontWeight: 'bold' }} className='form-control' /></label>
                            <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                          </div>
                        </Form>
                        )}                        
                        { film.filmBand &&
                          <h3 className="text-muted">{film.filmBand}</h3>
                        }
                        { film.filmSchool &&
                          <h3 className="text-muted">{film.filmSchool} ({film.filmSchoolLevel})</h3>
                        }
                        <p>
                        {film.filmLanguage}< br/>
                        <small className="text-muted">{film.filmCity} - {film.filmState} ({film.filmCountry}), {film.filmYear}</small><br/>
                      <small className="text-muted">{film.filmLengthFixed ? film.filmLengthFixed : film.filmLength} minuts.</small><br /></p>
                        </div>
                        <div className="col-sm-4">
                              {film.imageCover && !this.authUser.roles['EDITOR'] && (
                              <a href={film.imageCover} target="_blank" rel="noopener noreferrer">
                                <img alt="FIC-CAT" src={film.imageCover} style={{ maxWidth: '100%' }} />
                              </a>
                              )}
                              { this.authUser.roles['EDITOR'] && (
                              <FileUploader
                                hidden
                                accept="image/*"
                                imageField='imageCover'
                                imageUrl={film.imageCover}
                                onUploadSuccess={this.handleUploadSuccess}
                              />
                              )}
                        </div>
                    </div>
                    <hr />
                    { film.filmLinkType === 'vimeo' &&
                      <div style={{ margin: '0 auto 10px', maxWidth: '640px'}}>
                        <iframe title="vimeo"  src={"https://player.vimeo.com/video/" + film.filmLinkId } width="640" height="380" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    }
                    { film.filmLinkType === 'youtube' &&
                      <div style={{ margin: '0 auto 10px', maxWidth: '640px'}}>
                        <iframe title="youtube" width="640" height="380" src={"https://www.youtube.com/embed/" + film.filmLinkId } frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                       { film.eventLinkYouTube && !film.imageCover && ( 
                         <div>
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/maxresdefault.jpg"} style={{ maxWidth: '100%' }} />
                          <img src={"http://i3.ytimg.com/vi/" + film.filmLinkId + "/hqdefault.jpg"} style={{ maxWidth: '100%' }} />
                          </div>
                        )}
                      </div>
                    }
                    { film.filmLinkType === 'drive' &&
                    <div style={{ margin: '0 auto 10px', maxWidth: '640px'}}>
                      <iframe title="drive" src={film.filmLink.replace('view?','preview?')} frameBorder="0" width="640" height="380"></iframe>
                    </div>
                    }
                    <div className="row">
                      { film.filmPass && 
                      <dl className="col-sm-6">
                        <dt>Password:</dt>
                        <dd>{film.filmPass}</dd>
                      </dl>
                      }
                     { film.filmLink && ( 
                      <dl  className="col-sm-6">
                        <dt>Enllaç de visionat:</dt>
                        <dd>
                          <a href={film.filmLink} target="_blank" rel="noopener noreferrer">{film.filmLink}</a>
                        </dd>
                      </dl>
                      )}
                    </div>
                    <hr />
                    <dl>
                          <dt>Sinopsi:</dt>
                          <dd>{film.filmSynopsis}</dd>
                    </dl>
                    <hr />
                    <div className="row">
                      <div className='col-sm-6'>
                        <dl  className='mb-0'>
                          <dt>Director/a:</dt>
                          <dd>{film.filmDirector}</dd>
                        </dl>
                        {!this.authUser.roles['JURAT'] && (
                        <dl className='mb-0'>
                          <dt>E-mail director/a:</dt>
                          <dd>{film.filmDirectorEmail}</dd>
                        </dl>
                        )}
                        <dl>
                          <dt>Intèrprets principals:</dt>
                          <dd>{film.filmActoring}</dd>
                          <dt>Muntador/a:</dt>
                          <dd>{film.filmSpecialEdition}</dd>
                        </dl>
                      </div>
                      <div className='col-sm-6'>
                          { film.producerCompany &&
                          <dl>
                            <dt>Empresa productora:</dt>
                            <dd>{film.producerCompany}</dd>
                            <dt>Web de la productora:</dt>
                            <dd>{film.producerSite}</dd>
                            <dt>Nom del productor/a:</dt>
                            <dd>{film.producerName}</dd>
                          </dl>
                          }
                          { film.filmSchoolTutor &&
                          <dl>
                            <dt>Nom del tutor/a responsable de l'obra:</dt>
                            <dd>{film.filmSchoolTutor}</dd>
                          </dl>
                          }
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className='col-sm-12'>
                        {film.filmFestivals && 
                        <dl>
                          <dt>Premis i Festivals:</dt>
                          <dd>{film.filmFestivals}</dd>
                        </dl>
                        }
                      </div>
                    </div>
                    <hr />
                    { !this.authUser.roles['JURAT'] && (
                      <>
                    <div className="row">
                      <div className='col-sm-6'>
                        <dl>
                          <dt>Persona de contacte:</dt>
                          <dd>{film.contactName}</dd>
                          <dt>Rol:</dt>
                          <dd>{film.contactRole}</dd>
                        </dl>
                      </div>
                      <div className='col-sm-6'>
                          <dl>
                          <dt>E-mail:</dt>
                          <dd>{film.contactEmail}</dd>
                          <dt>Telefon:</dt>
                          <dd>{film.contactPhone}</dd>
                          </dl>
                      </div>
                    </div>
                    <hr />
                    </>
                    )}
                    { (film.imageFrame1 || film.imageFrame2 || film.imageFrame3 || this.authUser.roles['EDITOR']) &&
                    <div className="row">
                      <div className='col-sm-4'>
                        {film.imageFrame1 && !this.authUser.roles['EDITOR'] && ( 
                          <a href={film.imageFrame1} target="_blank" rel="noopener noreferrer">
                            <img alt="Frame 1" src={film.imageFrame1} style={{ maxWidth: '100%' }} />
                          </a>
                        )}
                          { this.authUser.roles['EDITOR'] && (
                          <FileUploader
                            hidden
                            accept="image/*"
                            imageField='imageFrame1'
                            imageUrl={film.imageFrame1}
                            onUploadSuccess={this.handleUploadSuccess}
                          />
                          )}
                      </div>
                      <div className='col-sm-4'>
                        {film.imageFrame2 && !this.authUser.roles['EDITOR'] && ( 
                          <a href={film.imageFrame2} target="_blank" rel="noopener noreferrer">
                            <img alt="Frame 2" src={film.imageFrame2} style={{ maxWidth: '100%' }} />
                          </a>
                        )}
                          { this.authUser.roles['EDITOR'] && (
                          <FileUploader
                            hidden
                            accept="image/*"
                            imageField='imageFrame2'
                            imageUrl={film.imageFrame2}
                            onUploadSuccess={this.handleUploadSuccess}
                          />
                          )}
                      </div>
                      <div className='col-sm-4'>
                        { film.imageFrame3 && !this.authUser.roles['EDITOR'] && ( 
                          <a href={film.imageFrame3} target="_blank" rel="noopener noreferrer">
                            <img alt="Frame 3" src={film.imageFrame3} style={{ maxWidth: '100%' }} />
                          </a>
                        )}
                          { this.authUser.roles['EDITOR'] && (
                          <FileUploader
                            hidden
                            accept="image/*"
                            imageField='imageFrame3'
                            imageUrl={film.imageFrame3}
                            onUploadSuccess={this.handleUploadSuccess}
                          />
                          )}
                      </div>
                      <hr />
                    </div>
                  }
                      { film.message &&
                        <div className='pt-4'>
                            <dl>
                              <dt>Info adicional:</dt>
                              <dd>{film.message}</dd>
                          </dl>
                          <hr/>
                        </div>
                      }
                       { !this.authUser.roles['JURAT'] && (
                      <div className="row">
                      <dl className='col-sm'>
                            <dt>Info</dt>
                            <dd>{film.acceptInfo}</dd>
                          </dl>
                          <dl className='col-sm'>
                            <dt>Bases</dt>
                            <dd>{film.acceptLegal}</dd>
                          </dl>
                          <dl className='col-sm'>
                            <dt>Online</dt>
                            <dd>{film.acceptOnline}</dd>
                          </dl>
                          <dl className='col-sm'>
                            <dt>Propietari</dt>
                            <dd>{film.acceptOwn}</dd>
                        </dl>
                      </div>
                       )}
                    </div>
                </div>
              </div>
              <div className='col-sm-4'>
                { this.authUser.roles['ADMIN'] && (
                  <div>
                  <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="isSelectableForm" ref='isSelectableForm' className={this.state.isSelectable === undefined ? 'bg-warning' : (this.state.isSelectable === false ? 'bg-danger text-white' : 'bg-success text-white') } >
                    Compleix les bases?
                    <div style={{ padding: '5px 0px 0' }} className='form-group '>
                      <label htmlFor='isSelectable' style={{ padding: '0 20px 0' }}><input name="isSelectable" defaultChecked={film.isSelectable === true} onChange={this.updateIsSelectable.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                      <label htmlFor='isSelectable'><input name="isSelectable" defaultChecked={film.isSelectable === false} onChange={this.updateIsSelectable.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                    </div>
                  </Form>
                  <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} className='bg-secondary text-white' id="filmLengthFixedForm" ref='filmLengthFixedForm'>
                    Duració real (Ex: 120)
                    <div style={{ padding: '5px 0px 0' }} className='form-group '>
                      <label htmlFor='filmLengthFixed' style={{ padding: '0 20px 0', display: 'inline-block' }}><input name="filmLengthFixed" onBlur={this.updateLength.bind(this)} type='input' defaultValue={film.filmLengthFixed ? film.filmLengthFixed : '' } style={{ width: '100px', display: 'inline-block' }} className='form-control' /></label>
                      <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                    </div>
                  </Form>
                  { film.isSelectable && (this.authUser.roles['EDITOR'] || this.authUser.roles['ADMIN'] ) && (
                  <Form style={{ padding: '20px 0 0 0', display: 'block', overflow: 'hidden' }} id="isSelectedForm" ref='isSelectedForm'>
                    <strong>Es selecció oficial?</strong>
                    <div style={{ padding: '5px 0px 0' }} className='form-group '>
                      <label htmlFor='isSelected' style={{ padding: '0 20px 0' }}><input name="isSelected" defaultChecked={film.isSelected === true} onChange={this.updateIsSelected.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                      <label htmlFor='isSelected'><input name="isSelected" defaultChecked={film.isSelected === false} onChange={this.updateIsSelected.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                    </div>
                  </Form>
                  )}
                  { (this.authUser.roles['ADMIN'] ) &&
                    <div>
                      <Form style={{ display: 'block', overflow: 'hidden' }} id="filmLinkForm" ref='filmLinkForm'>
                        <div className='form-group '>
                          <br />
                          <label htmlFor='filmLink' style={{ padding: '0', display: 'block' }}>Enllaç vídeo:</label>
                          <input name="filmLink" onBlur={this.updateLink.bind(this)} type='input' defaultValue={film.filmLink ? film.filmLink : '' } style={{ width: '100%', display: 'block' }} className='form-control' />
                          <br />
                          <label htmlFor='filmPass' style={{ padding: '0', display: 'block' }}>Contrasenya vídeo:</label>
                          <input name="filmPass" onBlur={this.updatePass.bind(this)} type='input' defaultValue={film.filmPass ? film.filmPass : '' } style={{ width: '100%', display: 'block' }} className='form-control' />
                          <br />
                          <button onClick={ (e) => { e.preventDefault() } }>Actualitzar</button>
                        </div>
                      </Form>
                    </div>
                  }
                  { film.isSelected && this.authUser.roles['EDITOR'] && (
                  <div>
                    <Form style={{ padding: '0px 0 0 10px', display: 'none', overflow: 'hidden' }} id="eventDateForm" ref='eventDateForm'>
                        Data de Projecció / Destacat online:
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='eventDate' style={{ padding: '0 10px 0', display: 'inline-block' }}><input name="eventDate" onBlur={this.updateEventDate.bind(this)} type='date' defaultValue={film.eventDate ? film.eventDate : '' } style={{ width: '180px', display: 'inline-block' }} className='form-control' /></label>
                          <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                        </div>
                      </Form>
                      <Form id="shortGroupForm" ref='shortGroupForm'>
                        <Form.Group controlId="shortGroup">
                          <Form.Label>Grup</Form.Label>
                          <Form.Control as="select"
                            onBlur={this.updateShortGroup.bind(this)}
                            defaultValue={film.shortGroup ? film.shortGroup: ''}
                            ref={shortGroup => this.inputShortGroup = shortGroup}
                          >
                            <option value=''>Selecciona una opció...</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={0}>Nit Jove</option>
                            </Form.Control>
                          <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                        </Form.Group>
                      </Form>
                      <Form>
                        <label htmlFor='hideSelected' style={{display:'none'}}><input type="checkbox" id="hideSelected" name="hideSelected" onChange={this.updateHideSelected.bind(this)} defaultChecked={film.hideSelected} /> <small>Ocultar a la Selecció Oficial</small></label>
                      </Form>
                    <hr />
                    <div >
                      <Form id="isOfflineForm" ref='isOfflineForm' style={{ padding: '10px 0 0 0', display: 'block', overflow: 'hidden' }}>
                        <strong>Projecció física?</strong>
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='isOffline' style={{ padding: '0 20px 0' }}><input name="isOffline" defaultChecked={film.isOffline === true} onChange={this.updateIsOffline.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                          <label htmlFor='isOffline'><input name="isOffline" defaultChecked={film.isOffline === false} onChange={this.updateIsOffline.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                        </div>
                      </Form>
                      { film.isOffline && this.authUser.roles['EDITOR'] && (
                        <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="eventTimeForm" ref='eventTimeForm'>
                          Hora de Projecció:
                          <div style={{ padding: '5px 0px 0' }} className='form-group '>
                            <label htmlFor='eventTime' style={{ padding: '0 10px 0', display: 'inline-block' }}><input name="eventDate" onBlur={this.updateEventTime.bind(this)} type='time' defaultValue={film.eventTime ? film.eventTime : '' } style={{ width: '180px', display: 'inline-block' }} className='form-control' /></label>
                            <button onClick={ (e) => { e.preventDefault() } }>Guardar</button>
                          </div>
                        </Form>
                      )}
                      <hr />
                      <Form id="isOnlineForm" ref='isOnlineForm' >
                        <strong>Online?</strong>
                        <div style={{ padding: '5px 0px 0' }} className='form-group '>
                          <label htmlFor='isOnline' style={{ padding: '0 20px 0' }}><input name="isOnline" defaultChecked={film.isOnline === true} onChange={this.updateIsOnline.bind(this)} type='radio' value="true" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> Si</label>
                          <label htmlFor='isOnline'><input name="isOnline" defaultChecked={film.isOnline === false} onChange={this.updateIsOnline.bind(this)} type='radio' value="false" className='form-control' style={{ width: '16px', height: '16px', display: 'inline-block' }} /> No</label>
                        </div>
                      </Form>
                      { film.isOnline && this.authUser.roles['EDITOR'] && (
                        <div>
                            <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkYouTubeForm" ref='eventLinkYouTubeForm'>
                              Enllaç YouTube:
                              <label htmlFor='eventLinkYouTube' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkYouTube" onBlur={this.updateEventLinkYouTube.bind(this)} type='url' defaultValue={film.eventLinkYouTube ? film.eventLinkYouTube : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                              <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkTv3Form" ref='eventLinkTv3Form'>
                              Enllaç TV3 a la carta:
                              <label htmlFor='eventLinkTv3' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkTv3" onBlur={this.updateEventLinkTv3.bind(this)} type='url' defaultValue={film.eventLinkTv3 ? film.eventLinkTv3 : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                            <Form style={{ padding: '0', display: 'block', overflow: 'hidden' }} id="eventLinkFilminForm" ref='eventLinkFilminForm'>
                              Enllaç Filmin:
                              <label htmlFor='eventLinkFilmin' style={{ padding: '0 0 20px', display: 'block', width: '100%' }}><input name="eventLinkFilmin" onBlur={this.updateEventLinkFilmin.bind(this)} type='url' defaultValue={film.eventLinkFilmin ? film.eventLinkFilmin : '' } style={{ width: '100%'}} className='form-control' /></label>
                            </Form>
                        </div>
                      )}   
                    </div>
                  </div>
                  )}
                </div>
              )}
                { (CONFIG.RATE_FILMS || this.authUser.roles['JURAT'] ) && (   
                  <Form noValidate validated={ this.state.validated } id="userFilm" onSubmit={this.sendUserFilm.bind(this)} style={{ padding: '10px'}} className='bg-light'>
                    <Form.Group controlId="userFilmRank">
                      <Form.Label>Valoració (sobre 5)</Form.Label>
                      <Form.Control as="select"
                        required
                        defaultValue={userFilm ? userFilm.userFilmRank : ''}
                        ref={userFilmRank => this.inputUserFilmRank = userFilmRank}
                      >
                        <option value=''>Selecciona una opció...</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        </Form.Control>
                      <Form.Control.Feedback type="invalid">{ MESSAGES.FIELD_UNSELECTED }</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="userFilmNote">
                      <Form.Label><strong>Notes</strong><br/>(El millor, el pitjor...)</Form.Label>
                      <Form.Control 
                        defaultValue={userFilm.userFilmNote}
                        as="textarea" rows="12" ref={userFilmNote => this.inputUserFilmNote = userFilmNote} />
                    </Form.Group>
                    <button type='submit' className='btn btn-lg btn-secondary'><strong>Guardar valoració</strong></button>
                  </Form>
                  )}
                  { CONFIG.RATE_FILMS !== true && ( 
                    <div className='bg-light' style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }}>
                        <p><b>La teva valoració:</b></p>
                        <h2>{userFilm.userFilmRank ? userFilm.userFilmRank : ''}</h2>
                        {userFilm.userFilmNote ? userFilm.userFilmNote : ''}
                        <br/><br/>
                      </div>
                   )}
                  { film.isSelected && this.authUser.roles['EDITOR'] && (
                    <div> 
                      <hr />
                      <h3><strong>INFO PER LA APP:</strong></h3>
                    <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="appShortDescriptionForm" ref='appShortDescriptionForm'>
                      Descripció curta:
                      <div style={{ padding: '5px 0px 0' }} className='form-group '>
                        <label htmlFor='appTechnicalCast' style={{ width: '100%' }}>
                            <textarea rows="3" name="appShortDescription" onBlur={this.updateAppShortDescription.bind(this)} defaultValue={film.appShortDescription  ? film.appShortDescription : '' } style={{ width: '100%', display: 'block' }} className='form-control'></textarea>
                          </label>
                      </div>
                    </Form>
                    <Form style={{ padding: '10px 0 0 10px', display: 'block', overflow: 'hidden' }} id="appTechnicalCastForm" ref='appTechnicalCastForm'>
                      Fitxa tecnica:
                      <div style={{ padding: '5px 0px 0' }} className='form-group '>
                        <label htmlFor='appTechnicalCast' style={{ width: '100%' }}>
                            <textarea rows="25" name="appTechnicalCast" onBlur={this.updateAppTechnicalCast.bind(this)} defaultValue={film.appTechnicalCast  ? film.appTechnicalCast : '' } style={{ width: '100%', display: 'block' }} className='form-control'></textarea>
                          </label>
                      </div>
                    </Form>
                    </div>
                  )}  
              </div>
          </div>
        ) : (
          <React.Fragment>
              <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
          </React.Fragment>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);