import React from 'react';
import Button from 'react-bootstrap/Button'
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Button variant="outline-danger" type="button" onClick={firebase.doSignOut}>
    Sortir
  </Button>
);

export default withFirebase(SignOutButton);
