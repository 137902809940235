import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import FilmsPage from '../FormFilms';
import PasswordForgetPage from '../PasswordForget';
import FilmsListPage from '../FilmsList';
import FilmsTopPage from '../FilmsTop';
import FilmsChoicePage from '../FilmsChoice';
import FilmsJudgePage from '../FilmsJudge';
import FilmsCommPage from '../FilmsCommunication';
import MailsChoicePage from '../MailList/selected';
import MailsCompetitorsPage from '../MailList/competitors';
import FilmsViewPage from '../FilmsView';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DashboardPage from '../Dashboard';

import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import { withAuthentication } from '../Session';
import { object } from 'underscore';

let authUser = JSON.parse(localStorage.getItem('authUser'));

const App = (props) => (
  <Router>
          <Navigation />
        <div className='container' style={{ padding: '20px', maxWidth: '1260px', margin: '0 auto'  }}>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          { CONFIG.SIGNUP_NEW_USERS && (
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          )}
          <Route
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route exact path={ROUTES.FORM_FILMS} component={FilmsPage} />
          <Route exact path={ROUTES.FORM_SHORTS} component={FilmsPage} />
          <Route exact path={ROUTES.FORM_DOCS} component={FilmsPage} />
          <Route exact path={ROUTES.FORM_SCHOOLS} component={FilmsPage} />
          <Route exact path={ROUTES.FORM_MUSIC} component={FilmsPage} />
          <Route exact path={ROUTES.FORM_YOUNG} component={FilmsPage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ITEM + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:uid'} component={FilmsViewPage} />
          <Route path={ROUTES.CHOICE + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={FilmsChoicePage} />
          { (authUser && typeof authUser['roles'] === 'object' && !authUser.roles['JURAT']) ? (    
          <>
            <Route path={ROUTES.HOME} render={() => (<Redirect to={ROUTES.DASHBOARD} />)} />
            <Route exact path={ROUTES.DASHBOARD + '/:type?'} component={DashboardPage} />
            <Route exact path={ROUTES.LIST + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={FilmsListPage} />
            <Route path={ROUTES.CHOICE_JUDGE + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={FilmsJudgePage} />
            <Route path={ROUTES.CHOICE_COMM + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={FilmsCommPage} />
            <Route path={ROUTES.TOP + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={FilmsTopPage} />
            <Route path={ROUTES.MAIL_SELECTED + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={MailsChoicePage} />
            <Route path={ROUTES.MAIL_COMPETITOR + '/:type(llargs|documentals|curts|centres-educatius|videoclips|jove)/:curs?'} component={MailsCompetitorsPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
          </>
          ) : (
            <>
              <Route path={ROUTES.HOME} render={() => (<Redirect to={ROUTES.CHOICE + '/' + (authUser && typeof authUser['roles'] === 'object' && authUser.roles['JURAT'] == 'JOVE' ? 'jove' : 'llargs')} />)} />
            </>
          )}
    </div>
  </Router>
);

export default withAuthentication(App);
