import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash'

import Alert from 'react-bootstrap/Alert'

import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as CONFIG from '../../constants/config';

let filmType = '';

class MailsPage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.type = 'JOVES';
      break;
    }
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
        <h1>{ MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</h1>
        { this.state.type === 'CENTRES' && (
          <>
            <span>Curs: </span>
            { window.location.pathname === ROUTES.CHOICE_CENTRES ? (
              <strong>&#x27A4; Tots</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES }>Tots</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.CHOICE_CENTRES + '/primaria' ? (
              <strong>&#x27A4; Primaria</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES + '/primaria' }>Primaria</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.CHOICE_CENTRES + '/secundaria' ? (
              <strong>&#x27A4; Secundaria</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES + '/secundaria' }>Secundaria</Link>
            )}
            &nbsp;
          <br/><br/>
          </>
        )}
        <Films />
      </div>
    )
  }
}

const FilmList = ({ films }) => (
  <>
    {films.map(film => (
      <FilmItemFirebase key={film.uid} film={film} />
    ))}
  </>
);

class FilmItem extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      firebase: props.firebase,
      film: props.film,
    }

  }
  UNSAFE_componentWillReceiveProps () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + this.authUser.uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  render () {
    const { film, userFilm } = this.state;
    return (
      <React.Fragment>
        { film.isSelectable && (
          <span>
            {film.contactEmail}<br />
          </span>
        )}
      </React.Fragment>
    )
  }
}

const FilmItemFirebase = withFirebase(FilmItem);


class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalLength: 0,
      totalLengthSub1: 0, // FICCIO + ANIMACIO + VIDEOCREACIO | PRIMARIA
      totalLengthSub2: 0, // DOCUMENTAL | SECUNDARIA
      maxTotalLength: 0,
      maxTotalLengthSub1: 0,
      maxTotalLengthSub2: 0,
      totalPremiere: 0,
      totalTarragona: 0,
      totalStates: {},
      totalTypes: {},
      maxChoiceNumber: 0,
      films: [],
      API: null,
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.state.maxTotalLength = CONFIG.MAX_CURTS;
        this.state.maxTotalLengthSub1 = CONFIG.MAX_CURTS_FICCIO;
        this.state.maxTotalLengthSub2 = CONFIG.MAX_CURTS_DOCS;
        this.filmTypeName = 'CURTS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.state.maxChoiceNumber = CONFIG.MAX_DOCS;
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.state.maxTotalLength = CONFIG.MAX_CENTRES;
        this.state.maxTotalLengthSub1 = CONFIG.MAX_CENTRES_PRIMARIA;
        this.state.maxTotalLengthSub2 = CONFIG.MAX_CENTRES_SECUNDARIA;
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.state.maxTotalLength = CONFIG.MAX_CURTS_VIDEOCLIPS;
        this.filmTypeName = 'VIDEOCLIPS';
      break;
      case 'jove':
        this.state.API = this.props.firebase.youngs();
        this.state.maxTotalLength = CONFIG.MAX_CURTS_VIDEOCLIPS;
        this.filmTypeName = 'JOVES';
      break;
      case 'llargs':
        default:
          this.state.maxChoiceNumber = CONFIG.MAX_LLARGS;
          this.state.API = this.props.firebase.films();
          this.filmTypeName = 'LLARGS';
        break;
    }
  }
  updateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        
        // GET USER RANK AND ATTACH IT TO THE ARRAY

        let scopeKeeper = this;
        let response = [];
        let userList = [];
        let userListIds = [];
        let userTotal = 0;
        let totalLength = 0,
            totalLengthSub1 = 0,
            totalLengthSub2 = 0,
            totalPremiere = 0,
            totalTypes = {},
            totalStates = {};

        // Get list of users
        scopeKeeper.props.firebase.users().once('value', snapshotUserList => {
          if (snapshotUserList.val()) {
            userList = snapshotUserList.val() 
            userListIds = Object.keys(userList);
          } else {
            userList = []
          }
        
          // For each item on the film list...
          filmList.forEach( function(element, index, array) {
            
            element.userFilmRanks = [];
            element.userFilmScore = 0;
            element.userFilmPeopleNumber = 0;
            element.userFilmPeopleNames = '';

            let score = false;

              userListIds.forEach( function(userElement, userIndex, userArray) {  
                
                // Get the user opinion value
                scopeKeeper.props.firebase.database().ref(CONFIG.USER_NOTES + userElement + '/' + element.uid).once('value', snapshotUserFilm => {
                  if (snapshotUserFilm.val() && element) {
                    score = snapshotUserFilm.val().userFilmRank;
                  } else {
                    score = false
                  }
                  element.userFilmRanks[userElement] = {
                    name: userList[userElement].username,
                    score: score,
                    uid: userElement
                  }
                  if (score > 0 ) {
                    element.userFilmPeopleNumber++
                    if (element.userFilmPeopleNumber > userTotal) {
                      userTotal = element.userFilmPeopleNumber;
                    }
                    element.userFilmScore += score
                    element.userFilmRank = ( element.userFilmPeopleNumber <= 1 ? (element.userFilmScore / 2).toFixed(2) : (element.userFilmScore / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmScoreRank = ( element.userFilmPeopleNumber >= (Math.round(userTotal / 3) + 1) ? element.userFilmRank : (element.userFilmRank / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmPeopleNames += userList[userElement].username + ': ' + score + ' / '
                  }
                  
                  if (response.indexOf(element.uid) === -1) {
                    response.push(element.uid);

                    function timestrToSec(timestr) {
                      if (timestr) {
                      var parts = timestr.split(":");
                      return (parts[0] * 60) +
                             (+parts[1]);
                      } else {
                        return '';
                      }
                    }
                    
                    function pad(num) {
                      if(num < 10) {
                        return "0" + num;
                      } else {
                        return "" + num;
                      }
                    }
                    
                    function formatTime(seconds) {
                      return [pad(Math.floor(seconds/60)),
                              pad(seconds%60),
                              ].join(":");
                    }
                   
                    if (element.isSelected) {

                      let State = element.filmState,
                          groupState = undefined;

                      if (element.filmState.toUpperCase().indexOf('TARRAGONA') !== -1) {
                        State = 'Tarragona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('BARCELONA') !== -1 || element.filmState.toUpperCase().indexOf('BAIX LLOBREGAT') !== -1 ) {
                        State = 'Barcelona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('GIRONA') !== -1) {
                        State = 'Girona'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().indexOf('LLEIDA') !== -1) {
                        State = 'LLeida'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf('VALENCIA') !== -1) {
                        State = 'Valencia'
                        groupState = 'Pais Valencia'
                      }  else if (element.filmState.toUpperCase().indexOf('ALACANT') !== -1) {
                        State = 'Alacant'
                        groupState = 'Pais Valencia'
                      } else if (element.filmState.toUpperCase().indexOf('MALLORCA') !== -1 || element.filmState.toUpperCase().indexOf('BALEARS') !== -1) {
                        State = 'Mallorca'
                        groupState = 'Illes Balears'
                      }  else if (element.filmState.toUpperCase().indexOf('MENORCA') !== -1) {
                        State = 'Menorca'
                        groupState = 'Illes Balears'
                      }

                      

                      if (groupState !== undefined ) {
                        if (totalStates[groupState] === undefined) {
                          totalStates[groupState] = {};
                          totalStates[groupState]['total'] = 0;
                        }
                        if (totalStates[groupState][State] === undefined) {
                          totalStates[groupState][State] = 0;
                        }
                        totalStates[groupState][State]++;
                        totalStates[groupState]['total']++;
                      } else {
                        if (totalStates[State] === undefined) {
                          totalStates[State] = {};
                          totalStates[State]['total'] = 0;
                        }
                        totalStates[State]['total']++;
                      }
                    }

                    // INCREASE SUBCATEGORIES COUNTER
                    if (filmType === 'curts' && (element.filmSchoolLevel === window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] === undefined)) {
                      if (totalTypes[element.filmCategory] === undefined) {
                        totalTypes[element.filmCategory] = 0;
                      }
                      totalTypes[element.filmCategory] = totalTypes[element.filmCategory] + 1;
                    }

                  }
              
                  if (response.length >= filmList.length ) {
                    let filmSortList =  _.filter( filmList, function(film) { return film.contactEmail !== '' } );
  
                    filmSortList = _.sortBy( filmSortList, 'userFilmScoreRank' ).reverse()

                    let filmListFiltered = {};

                    // ONLY FOR SCHOOLS FILTER IF /secundaria or /primaria
                    switch(window.location.pathname.split('/')[3]) {
                      case 'secundaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' || film.filmSchoolLevel === 'altres' ) } );
                      break;
                      case 'primaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
                      break;
                      default:
                        filmListFiltered = filmSortList;
                      break;
                    }
                    
                    scopeKeeper.setState({
                      films: filmListFiltered,
                      totalLength: totalLength,
                      totalLengthSub1: totalLengthSub1,
                      totalLengthSub2: totalLengthSub2,
                      totalPremiere: totalPremiere,
                      totalStates: totalStates,
                      totalTarragona: totalStates['Catalunya'] !== undefined && totalStates['Catalunya']['Tarragona'] !== undefined ? totalStates['Catalunya']['Tarragona'] : 0,
                      totalTypes: totalTypes,
                      loading: false
                    });
                  }

                })
              }) 

              
          });
        }); 
        // convert messages list from snapshot
        
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    this.updateFilmList()
  }
  UNSAFE_componentWillReceiveProps() {
    this.updateFilmList();
  }
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading } = this.state;
    return (
      <div>
        {films ? (
          <React.Fragment>
            {loading &&
              <React.Fragment>
                  <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
              </React.Fragment>
            }
            {!loading && 
            <>
              <FilmList films={films} />
            </>
            }
          </React.Fragment>
        ) : (
          <Alert variant="warning">No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(MailsPage);