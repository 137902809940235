import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import _ from 'lodash'

import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'

import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import * as MESSAGES from '../../constants/messages';
import * as CONFIG from '../../constants/config';

let filmType = '';

class HomePage extends Component {
  constructor(props) {
    super(props);
    filmType = props.match.params.type ? props.match.params.type : 'llargs';
    this.state = {
      type: null,
    }

    switch(filmType) {
      case 'curts':
        this.state.type = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.type = 'LLARGS';
      break;
      case 'documentals':
        this.state.type = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.type = 'CENTRES';
      break;
      case 'videoclips':
        this.state.type = 'VIDEOCLIPS';
      break;
    }
  }
  render () {
    return (
      <div style={{ padding: '20px 0 0'}}>
        <h1>{ MESSAGES[this.state.type].charAt(0).toUpperCase() }{ MESSAGES[this.state.type].slice(1) }</h1>
        { this.state.type === 'CENTRES' && (
          <>
            <span>Curs: </span>
            { window.location.pathname === ROUTES.CHOICE_CENTRES ? (
              <strong>&#x27A4; Tots</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES }>Tots</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.CHOICE_CENTRES + '/primaria' ? (
              <strong>&#x27A4; Primaria</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES + '/primaria' }>Primaria</Link>
            )}
            &nbsp;|&nbsp;
            { window.location.pathname === ROUTES.CHOICE_CENTRES + '/secundaria' ? (
              <strong>&#x27A4; Secundaria</strong>
            ) : (
              <Link to={ ROUTES.CHOICE_CENTRES + '/secundaria' }>Secundaria</Link>
            )}
            &nbsp;
          <br/><br/>
          </>
        )}
        <Films />
      </div>
    )
  }
}

class FilmList extends Component {
  constructor(props) {
    super(props);
    this.films = props.films;
    this.groups = props.groups;
  }
  render() {
    const films = this.films;
    const groups = this.groups;
    return (
    <tbody>
      {films.map((film, index) => (
        <>
        <FilmItemFirebase key={films[index].uid} groups={groups} previousFilm={films[(index - 1)] ? films[(index - 1)] : films[index]} films={films} film={film} />
        </>
      ))}
    </tbody>
    )
  }
} 

class FilmItem extends Component {
  constructor(props) {
    super(props);

    this.authUser = JSON.parse(localStorage.getItem('authUser'));

    this.shortGroup = 0;

    this.previousFilm = props.previousFilm;

    this.groups = props.groups;

    let groupDuration = Math.round(this.groups[props.film.shortGroup]) + Math.round(props.film.filmLengthFixed ? props.film.filmLengthFixed : props.film.filmLength);


    if (!isNaN(groupDuration)){
      this.groups[props.film.shortGroup] = groupDuration;
    } 

    this.state = {
      firebase: props.firebase,
      film: props.film,
      previousFilm: props.previousFilm,
      groups: this.groups
    }
  }

  componentDidMount () {
    this.props.firebase.database().ref(CONFIG.USER_NOTES + this.authUser.uid + '/' + this.state.film.uid).once('value', snapshotUserFilm => {
      if (snapshotUserFilm.val()) {
        this.setState({
          userFilm: snapshotUserFilm.val()
        });
      } else {
        this.setState({
          userFilm: {
            userFilmNote: '',
            userFilmRank: ''
          }
        });
      }
    })
  }

  render () {
    const { film, userFilm, key, groups } = this.state;
    return (
      <React.Fragment>
        { ( film.isSelectable) && (
          <>
          <tr style={ userFilm && (userFilm.userFilmRank > 0) ? { background: 'rgba(40,167,69,.3)' } : {} }>
            <td>
            <strong>{film.filmName}</strong>
            </td>
            <td>{film.filmDirector}{ film.filmBand && ( <span> | Intèrpret: {film.filmBand} </span>)}</td>
            <td>
                {film.producerName} ({film.producerCompany})
            </td>
            <td>
              {film.filmActoring}
            </td>
            <td>{film.filmSynopsis}</td>
            <td>
            { film.filmState.toUpperCase().indexOf('TARRAGONA') !== -1 ? (
                <span className="badge badge-warning">{film.filmState}</span>
              ) : (
                <small>{film.filmState}</small>
              )}
            </td>
            <td style={{ textAlign: 'right'}}>{film.filmLengthFixed ? film.filmLengthFixed : film.filmLength }'</td>
          </tr>
          </>
        )}
      </React.Fragment>
    )
  }
}

const FilmItemFirebase = withFirebase(FilmItem);


class FilmsBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      totalLength: 0,
      totalLengthSub1: 0, // FICCIO + ANIMACIO + VIDEOCREACIO | PRIMARIA
      totalLengthSub2: 0, // DOCUMENTAL | SECUNDARIA
      maxTotalLength: 0,
      maxTotalLengthSub1: 0,
      maxTotalLengthSub2: 0,
      totalPremiere: 0,
      totalOnline: 0,
      totalTarragona: 0,
      totalStates: {},
      totalTypes: {},
      maxChoiceNumber: 0,
      films: [],
      API: null,
      groups: []
    };

    switch(filmType) {
      case 'curts':
        this.state.API = this.props.firebase.shorts();
        this.state.maxTotalLength = CONFIG.MAX_CURTS;
        this.state.maxTotalLengthSub1 = CONFIG.MAX_CURTS_FICCIO;
        this.state.maxTotalLengthSub2 = CONFIG.MAX_CURTS_DOCS;
        this.filmTypeName = 'CURTS';
      break;
      case 'llargs':
      default:
        this.state.maxChoiceNumber = CONFIG.MAX_LLARGS;
        this.state.API = this.props.firebase.films();
        this.filmTypeName = 'LLARGS';
      break;
      case 'documentals':
        this.state.API = this.props.firebase.docs();
        this.state.maxChoiceNumber = CONFIG.MAX_DOCS;
        this.filmTypeName = 'DOCS';
      break;
      case 'centres-educatius':
        this.state.API = this.props.firebase.schools();
        this.state.maxTotalLength = CONFIG.MAX_CENTRES;
        this.state.maxTotalLengthSub1 = CONFIG.MAX_CENTRES_PRIMARIA;
        this.state.maxTotalLengthSub2 = CONFIG.MAX_CENTRES_SECUNDARIA;
        this.filmTypeName = 'CENTRES';
      break;
      case 'videoclips':
        this.state.API = this.props.firebase.musics();
        this.state.maxTotalLength = CONFIG.MAX_CURTS_VIDEOCLIPS;
        this.filmTypeName = 'VIDEOCLIPS';
      break;
    }
  }
  getFilmsByIsSelectable( films, value ) {
    let filmsSelectable = [];
    if (films !== null) {
      for ( var k = 0; k < films.length; k++) {
        if (films[k].isSelectable === value) {
          filmsSelectable.push(films[k])
        }
      }
    }
    return filmsSelectable;
  }
  updateFilmList() {
    this.setState({ loading: true });
    this.state.API.on('value', snapshot => {
      const filmObject = snapshot.val();
      if (filmObject) {
        const filmList = Object.keys(filmObject).map(key => ({
          ...filmObject[key],
          uid: key,
        }));
        
        // GET USER RANK AND ATTACH IT TO THE ARRAY

        let scopeKeeper = this;
        let response = [];
        let userList = [];
        let userListIds = [];
        let userTotal = 0;
        let totalLength = 0,
            totalLengthSub1 = 0,
            totalLengthSub2 = 0,
            totalPremiere = 0,
            totalOnline = 0,
            totalTypes = {},
            totalStates = {};

        // Get list of users
        scopeKeeper.props.firebase.users().once('value', snapshotUserList => {
          if (snapshotUserList.val()) {
            userList = snapshotUserList.val() 
            userListIds = Object.keys(userList);
          } else {
            userList = []
          }
        
          // For each item on the film list...
          filmList.forEach( function(element, index, array) {
            
            element.userFilmRanks = [];
            element.userFilmScore = 0;
            element.userFilmPeopleNumber = 0;
            element.userFilmPeopleNames = '';

            let score = false;

              userListIds.forEach( function(userElement, userIndex, userArray) {  
                
                // Get the user opinion value
                scopeKeeper.props.firebase.database().ref(CONFIG.USER_NOTES + userElement + '/' + element.uid).once('value', snapshotUserFilm => {
                  if (snapshotUserFilm.val() && element) {
                    score = snapshotUserFilm.val().userFilmRank;
                  } else {
                    score = false
                  }
                  element.userFilmRanks[userElement] = {
                    name: userList[userElement].username,
                    score: score,
                    uid: userElement
                  }
                  if (score > 0 ) {
                    element.userFilmPeopleNumber++
                    if (element.userFilmPeopleNumber > userTotal) {
                      userTotal = element.userFilmPeopleNumber;
                    }
                    element.userFilmScore += score
                    element.userFilmRank = ( element.userFilmPeopleNumber <= 1 ? (element.userFilmScore / 2).toFixed(2) : (element.userFilmScore / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmScoreRank = ( element.userFilmPeopleNumber >= (Math.round(userTotal / 3) + 1) ? element.userFilmRank : (element.userFilmRank / element.userFilmPeopleNumber).toFixed(2) )
                    element.userFilmPeopleNames += userList[userElement].username + ': ' + score + ' / '
                  }
                  
                  if (response.indexOf(element.uid) === -1) {
                    response.push(element.uid);

                    function timestrToSec(timestr) {
                      if (timestr) {
                      var parts = timestr.split(":");
                      return (parts[0] * 60) +
                             (+parts[1]);
                      } else {
                        return '';
                      }
                    }
                    
                    function pad(num) {
                      if(num < 10) {
                        return "0" + num;
                      } else {
                        return "" + num;
                      }
                    }
                    
                    function formatTime(seconds) {
                      return [pad(Math.floor(seconds/60)),
                              pad(seconds%60),
                              ].join(":");
                    }

                    // INCREASE TOTAL SELECTED DURATION
                    if (element.isSelected ) {

                      let realLength = element.filmLengthFixed ? element.filmLengthFixed : element.filmLength
                      let tempLength = parseFloat(realLength.replace("''",'').replace("'",'.').replace("m ",'.').replace(',','.').replace(':','.').replace(/[^\d.-]/g, ''), 100).toFixed(2).toString().replace('.',':');
                      totalLength = formatTime(timestrToSec(totalLength) + timestrToSec(tempLength));
                      if (element.filmCategory === 'ficcio' && !element.filmSchoolLevel || element.filmCategory === 'videocreacio' && !element.filmSchoolLevel || element.filmCategory === 'animacio' && !element.filmSchoolLevel || element.filmSchoolLevel === 'primaria' || element.filmSchoolLevel === 'infantil' ) {
                        totalLengthSub1 = formatTime(timestrToSec(totalLengthSub1) + timestrToSec(tempLength));
                      }
                      if (element.filmCategory === 'documental' && !element.filmSchoolLevel || element.filmSchoolLevel === 'secundaria' || element.filmSchoolLevel === 'batxillerat' || element.filmSchoolLevel === 'altres' ) {
                        totalLengthSub2 = formatTime(timestrToSec(totalLengthSub2) + timestrToSec(tempLength));
                      }
                    }

                    // INCREASE TOTAL PREMIERE COUNTER
                    if (element.isSelected && element.filmPremiere  === 'Estrena' && (element.filmSchoolLevel === window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] === undefined)) {
                      totalPremiere++ 
                    }

                    if (element.isSelected && element.acceptOnline === 'Si'){
                      totalOnline++
                    } 

                    if (element.isSelected) {

                      let State = element.filmState,
                          groupState = undefined;

                      if (element.filmState.toUpperCase().indexOf('TARRAGONA') !== -1) {
                        State = 'Tarragona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('BARCELONA') !== -1 || element.filmState.toUpperCase().indexOf('BAIX LLOBREGAT') !== -1 ) {
                        State = 'Barcelona'
                        groupState = 'Catalunya'
                      } else if (element.filmState.toUpperCase().indexOf('GIRONA') !== -1) {
                        State = 'Girona'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().indexOf('LLEIDA') !== -1) {
                        State = 'LLeida'
                        groupState = 'Catalunya'
                      }  else if (element.filmState.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf('VALENCIA') !== -1) {
                        State = 'Valencia'
                        groupState = 'Pais Valencia'
                      }  else if (element.filmState.toUpperCase().indexOf('ALACANT') !== -1) {
                        State = 'Alacant'
                        groupState = 'Pais Valencia'
                      } else if (element.filmState.toUpperCase().indexOf('MALLORCA') !== -1 || element.filmState.toUpperCase().indexOf('BALEARS') !== -1) {
                        State = 'Mallorca'
                        groupState = 'Illes Balears'
                      }  else if (element.filmState.toUpperCase().indexOf('MENORCA') !== -1) {
                        State = 'Menorca'
                        groupState = 'Illes Balears'
                      }

                      

                      if (groupState !== undefined ) {
                        if (totalStates[groupState] === undefined) {
                          totalStates[groupState] = {};
                          totalStates[groupState]['total'] = 0;
                        }
                        if (totalStates[groupState][State] === undefined) {
                          totalStates[groupState][State] = 0;
                        }
                        totalStates[groupState][State]++;
                        totalStates[groupState]['total']++;
                      } else {
                        if (totalStates[State] === undefined) {
                          totalStates[State] = {};
                          totalStates[State]['total'] = 0;
                        }
                        totalStates[State]['total']++;
                      }
                    }

                    // INCREASE SUBCATEGORIES COUNTER
                    if (filmType === 'curts' && element.isSelected && (element.filmSchoolLevel === window.location.pathname.split('/')[3] || window.location.pathname.split('/')[3] === undefined)) {
                      if (totalTypes[element.filmCategory] === undefined) {
                        totalTypes[element.filmCategory] = 0;
                      }
                      totalTypes[element.filmCategory] = totalTypes[element.filmCategory] + 1;
                    }

                  }
              
                  if (response.length >= filmList.length ) {
                    let filmSortList =  _.filter( filmList, function(film) { return film.isSelected === true } );
  
                    filmSortList = _.sortBy( filmSortList, 'userFilmScoreRank' ).reverse()

                    let filmListFiltered = {};

                    // ONLY FOR SCHOOLS FILTER IF /secundaria or /primaria
                    switch(window.location.pathname.split('/')[3]) {
                      case 'secundaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'secundaria' || film.filmSchoolLevel === 'batxillerat' || film.filmSchoolLevel === 'altres' ) } );
                      break;
                      case 'primaria':
                        filmListFiltered = _.filter( filmSortList, function(film) { return (film.filmSchoolLevel === 'primaria' || film.filmSchoolLevel === 'infantil' ) } );
                      break;
                      default:
                        filmListFiltered = filmSortList;
                      break;
                    }
                    
                    scopeKeeper.setState({
                      films: filmListFiltered,
                      totalLength: totalLength,
                      totalLengthSub1: totalLengthSub1,
                      totalLengthSub2: totalLengthSub2,
                      totalPremiere: totalPremiere,
                      totalOnline: totalOnline,
                      totalStates: totalStates,
                      totalTarragona: totalStates['Catalunya'] !== undefined && totalStates['Catalunya']['Tarragona'] !== undefined ? totalStates['Catalunya']['Tarragona'] : 0,
                      totalTypes: totalTypes,
                      loading: false
                    });
                  }

                })
              }) 

              
          });
        }); 
        // convert messages list from snapshot
        
      } else {
        this.setState({ films: null, loading: false });
      }
    });
  }
  componentDidMount() {
    this.updateFilmList()
  }
  UNSAFE_componentWillReceiveProps() {
    this.updateFilmList();
  }
  componentWillUnmount() {
    if (this.state.API) {
      this.state.API.off();
    }
  }
  render() {
    const { films, loading } = this.state;
    const groups = [0,0,0,0,0,0,0,0,0,0,0,0];
    return (
      <div>
        {films ? (
          <React.Fragment>
            {loading &&
              <React.Fragment>
                  <img alt="Carregant..." src="/img/loading.gif" style={{ margin: '0 auto', maxWidth: '100%'}} />
              </React.Fragment>
            }
            {!loading && 
            <>
              <p>
                <span>Duració total:&nbsp;
                  <strong className={ (this.state.maxTotalLength > 0 && parseFloat(this.state.totalLength, 100) <= this.state.maxTotalLength) || this.state.maxTotalLength === 0 ? 'text-success' : 'text-danger' }>{this.state.totalLength}
                  { this.state.maxTotalLength > 0 && (
                      <>
                          &nbsp;de {this.state.maxTotalLength}:00 
                      </>
                    )}
                  </strong> | </span>
                { (filmType === 'curts' || filmType === 'centres-educatius') && (
                  <>
                  <span>
                    { filmType === 'curts' ? 'Ficció' : 'Primaria' }:&nbsp; 
                    <strong className={ (parseFloat(this.state.maxTotalLengthSub1, 100) > 0 && parseFloat(this.state.totalLengthSub1, 100) <= this.state.maxTotalLengthSub1) || this.state.maxTotalLengthSub1 === 0 ? 'text-success' : 'text-danger' }>{this.state.totalLengthSub1}
                    { this.state.maxTotalLengthSub1 > 0 && (
                      <>
                          &nbsp;de {this.state.maxTotalLengthSub1}:00 
                      </>
                    )}
                    </strong> | </span>
                  <span>{ filmType === 'curts' ? 'Documentals' : 'Secundaria' }:&nbsp;
                  <strong className={ (parseFloat(this.state.maxTotalLengthSub2, 100) > 0 && parseFloat(this.state.totalLengthSub2, 100) <= this.state.maxTotalLengthSub2) || this.state.maxTotalLengthSub2 === 0 ? 'text-success' : 'text-danger' }>{this.state.totalLengthSub2}
                  { this.state.maxTotalLengthSub2 > 0 && (
                      <>
                          &nbsp;de {this.state.maxTotalLengthSub2}:00
                      </>
                    )}
                  </strong></span>
                  </>
                )}<br/>
                <span>Número d'obres: <strong className={ (this.state.maxChoiceNumber > 0 && this.state.films.length === this.state.maxChoiceNumber) || this.state.maxChoiceNumber === 0 ? 'text-success' : 'text-danger' }>{this.state.films.length}
                { this.state.maxChoiceNumber > 0 && (
                  <>
                      &nbsp;de {this.state.maxChoiceNumber} 
                  </>
                )}
                &nbsp;</strong>
                </span>
                <span>| Estrenes:&nbsp;
                  <strong className={ this.state.totalPremiere > 0 ? 'text-success' : 'text-danger' } >{this.state.totalPremiere}</strong>
                </span>
                <span>&nbsp;| Online:&nbsp;
                  <strong className={ this.state.totalOnline > (this.state.films.length/2) ? 'text-success' : 'text-danger' } >{this.state.totalOnline}</strong>
                </span>
                <span>&nbsp;|&nbsp;Comarques tarragonines: <strong className={ this.state.totalTarragona > 0 ? 'text-success' : 'text-danger' }>{this.state.totalTarragona}
                &nbsp;</strong>
                </span>
                <br />
                <span className="text-muted">Per comunitats:&nbsp;
                  {Object.entries(this.state.totalStates).map(([key, value]) => {
                        return (<i key={key}>{key} ({value.total}) &nbsp; </i>);
                  })}
                </span>
                <br />
                { filmType === 'curts' && (
                  <span className="text-muted">Per categories: &nbsp;
                  {Object.entries(this.state.totalTypes).map(([key, value]) => {
                        return (<i key={key}>{key} ({value}) &nbsp; </i>);
                  })}
                  </span>
                )}

              </p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th style={{ textAlign: 'center'}}>Títol</th>
                  <th>Direcció</th>
                  <th>Producció</th>
                  <th>Repariment</th>
                  <th>Sinopsis</th>
                  <th>Tarragona</th>
                  <th style={{ textAlign: 'right'}}>Durada</th>
                </tr>
              </thead>
                <FilmList films={films} groups={groups} />
            </Table>
            </>
            }
          </React.Fragment>
        ) : (
          <Alert variant="warning">No hi ha produccions inscrites en aquesta categoría.</Alert>
        )}
      </div>
    );
  }
}
const Films = withFirebase(FilmsBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);