import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import * as CONFIG from '../../constants/config';
import * as MESSAGES from '../../constants/messages';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardGroup from 'react-bootstrap/CardGroup'
import Alert from 'react-bootstrap/Alert'

const Landing = ({ authUser }) => (
  <div className='container'>
      <img alt="FIC-CAT" style={{ width: '100px', margin: '0 20px 10px 0' }} src="/img/logo.png" />
      { (CONFIG.SIGNIN_FILMS || CONFIG.SIGNIN_YOUNG ||(authUser !== null && CONFIG.SIGNIN_FILMS_USER === true) ) ? (
      <div>
        <h2 className="mt-2 mb-4">Quin tipus de producció vols inscriure?</h2>
        { (CONFIG.SIGNIN_FILMS || (authUser !== null && CONFIG.SIGNIN_FILMS_USER === true) ) && (
            <CardGroup>
                <Card>
                  <a href={ROUTES.FORM_FILMS}>
                    <Card.Img variant="top" src="/img/llargs.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>{MESSAGES.LLARGS}</Card.Title>
                    <Card.Text>
                      {MESSAGES.LLARGS_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_FILMS} variant="success">{MESSAGES.SIGNIN_FILM}</Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <a href={ROUTES.FORM_DOCS}>
                    <Card.Img variant="top" src="/img/docs.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>{MESSAGES.DOCS}</Card.Title>
                    <Card.Text>
                      {MESSAGES.DOCS_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_DOCS} variant="success">{MESSAGES.SIGNIN_FILM}</Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <a href={ROUTES.FORM_SHORTS}>
                    <Card.Img variant="top" src="/img/curts.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>{MESSAGES.CURTS}</Card.Title>
                    <Card.Text>
                      {MESSAGES.CURTS_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_SHORTS} variant="success">{MESSAGES.SIGNIN_FILM}</Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <a href={ROUTES.FORM_MUSIC}>
                    <Card.Img variant="top" src="/img/videoclips.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>{MESSAGES.VIDEOCLIPS}</Card.Title>
                    <Card.Text>
                      {MESSAGES.VIDEOCLIPS_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_MUSIC} variant="success">{MESSAGES.SIGNIN_FILM}</Button>
                  </Card.Footer>
                </Card>
                <Card>
                  <a href={ROUTES.FORM_SCHOOLS}>
                    <Card.Img variant="top" src="/img/centres.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>{MESSAGES.CENTRES}</Card.Title>
                    <Card.Text>
                      {MESSAGES.CENTRES_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_SCHOOLS} variant="success">{MESSAGES.SIGNIN_FILM}</Button>
                  </Card.Footer>
                </Card>
            </CardGroup>
        )}
        { CONFIG.SIGNIN_YOUNG && (
          <CardGroup>
            <Card style={{ maxWidth: '20em'}}>
                  <a href={ROUTES.FORM_YOUNG}>
                    <Card.Img variant="top" src="/img/jove.jpg"/>
                  </a>
                  <Card.Body>
                    <Card.Title>Jove 48 Hores</Card.Title>
                    <Card.Text>
                      {MESSAGES.JOVES_FORM_DESC}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <Button href={ROUTES.FORM_YOUNG} variant="success">Inscriure Jove48h</Button>
                  </Card.Footer>
                </Card>
          </CardGroup>
        )}
      </div>
      ) : (
        <div>
          <h2 className="mb-5">{MESSAGES.SIGIN_FALSE_TITLE}</h2>
          <Alert variant='warning' >{MESSAGES.SIGIN_FALSE_DESC}</Alert>
        </div>
      )}
  </div>
);

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Landing);